import React, { useState, useEffect, useContext } from "react";

import * as cognito from "../libs/cognito";

export const AuthStatus = {
  Loading: "Loading",
  SignedIn: "SignedIn",
  SignedOut: "SignedOut",
};

const defaultState = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState([]);

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session = await getSession();
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
        });
        const { attributes, username } = await getAttributes();
        setAttrInfo(attributes);
        setSessionInfo((prev) => ({ ...prev, username }));
        setAuthStatus(AuthStatus.SignedIn);
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
    getSessionInfo();
  }, [setAuthStatus, setSessionInfo, authStatus]);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  async function signInWithEmail(username, password) {
    try {
      await cognito.signInWithEmail(username, password);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  // async function signInWithGoogle(googleUser) {
  //   try {
  //     await cognito.signInWithGoogle(googleUser);
  //     setAuthStatus(AuthStatus.SignedIn);
  //   } catch (err) {
  //     setAuthStatus(AuthStatus.SignedOut);
  //     throw err;
  //   }
  // }

  async function authenticateUser(access_token, id_token, refresh_token) {
    try {
      await cognito.authenticateUser(access_token, id_token, refresh_token);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function refreshSession() {
    const session = await getSession();
    if (!session.isValid()) {
      console.log("session is valid");
    } else {
      const refresh_token = session.getRefreshToken();
      try {
        await cognito.refreshSession(refresh_token);
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
  }

  async function getAttributeVerificationCode(attrName) {
    return await cognito.getAttributeVerificationCode(attrName);
  }

  async function verifyAttribute(confirmationCode, attrName) {
    return await cognito.verifyAttribute(confirmationCode, attrName);
  }

  async function signUpWithEmail(
    username,
    email,
    given_name,
    family_name,
    phone_number,
    password,
    content_type,
    version
  ) {
    await cognito.signUpUserWithEmail(
      username,
      email,
      given_name,
      family_name,
      phone_number,
      password,
      content_type,
      version
    );
  }

  function signOut() {
    cognito.signOut(() => {
      setAttrInfo([]);
      setSessionInfo({});
      setAuthStatus(AuthStatus.SignedOut);
    });
  }

  async function verifyCode(username, code) {
    try {
      await cognito.verifyCode(username, code);
    } catch (err) {
      alert(err);
      throw err;
    }
  }

  async function getSession() {
    const session = await cognito.getSession();
    return session;
  }

  async function getAttributes() {
    const attr = await cognito.getAttributes();
    return attr;
  }

  async function setAttribute(attr) {
    const res = await cognito.setAttribute(attr);
    return res;
  }

  async function setProfileAttribute(family_name, given_name) {
    const res = await cognito.setProfileAttribute(family_name, given_name);
    return res;
  }

  async function sendCode(username) {
    await cognito.sendCode(username);
  }

  async function forgotPassword(username, code, password) {
    await cognito.forgotPassword(username, code, password);
  }

  async function changePassword(oldPassword, newPassword) {
    await cognito.changePassword(oldPassword, newPassword);
  }

  async function initiateAuth(username) {
    await cognito.initiateAuth(username);
  }

  async function answerChallenge(code) {
    await cognito.answerChallenge(code);
  }

  const state = {
    authStatus,
    sessionInfo,
    attrInfo,
    //signInWithGoogle,
    authenticateUser,
    signUpWithEmail,
    signInWithEmail,
    refreshSession,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    initiateAuth,
    answerChallenge,
    setProfileAttribute,
    getAttributeVerificationCode,
    verifyAttribute,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
