const Email = ({ emailIsValid, setEmail, email, label, required }) => {
  return (
    <div className="form-group">
      <label>
        {label}
        {required && <span>*</span>}
      </label>
      <input
        type="email"
        value={email}
        onChange={(evt) => {
          setEmail(evt.target.value);
        }}
      />
      {!emailIsValid && (
        <div className="valid-message">
          Girdiğiniz e-posta adresi geçersiz. Lütfen geçerli bir e-posta adresi
          giriniz.
        </div>
      )}
    </div>
  );
};

export default Email;
