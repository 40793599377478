import { useEffect, useState } from "react";
import cs from "classnames";
import PhoneNumber from "./phoneNumber";
import { useValidPhoneNumber } from "../../hooks/useAuthHooks";

const Username = ({ usernameIsValid, setUsername }) => {
  const [isPhoneActive, setIsPhoneActive] = useState(false);
  const {
    phoneNumber,
    setPhoneNumber,
    countryCode,
    setCountryCode,
    phoneNumberIsValid,
  } = useValidPhoneNumber("");

  useEffect(() => {
    setUsername(countryCode.dial_code + phoneNumber);
  }, [phoneNumber, countryCode.dial_code, setUsername]);

  useEffect(() => {
    setUsername("");
    setPhoneNumber("");
  }, [isPhoneActive, setUsername, setPhoneNumber]);

  return (
    <div className="form-group">
      <label>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsPhoneActive(false);
          }}
          className={cs("label-phone-number", {
            ["label-active"]: !isPhoneActive,
          })}
        >
          E-Posta
        </a>{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsPhoneActive(true);
          }}
          className={cs("label-phone-number", {
            ["label-active"]: isPhoneActive,
          })}
        >
          / Telefon Numarası
        </a>
      </label>
      {!isPhoneActive ? (
        <input
          type="text"
          onChange={(evt) => {
            setUsername(evt.target.value);
          }}
        />
      ) : (
        <div className="phoneNumberDropdown">
          <PhoneNumber
            phoneNumberIsValid={phoneNumberIsValid}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
          />
        </div>
      )}
      {!usernameIsValid && !isPhoneActive && (
        <div className="valid-message">
          Lütfen E-posta veya telefon numaranızı giriniz
        </div>
      )}
    </div>
  );
};

export default Username;
