const GivenName = ({
  givenNameIsValid,
  givenName,
  setGivenName,
  label,
  required,
}) => {
  return (
    <div className="form-group">
      <label>
        {label}
        {required && <span>*</span>}
      </label>
      <input
        type="text"
        value={givenName}
        onChange={(evt) => {
          setGivenName(evt.target.value);
        }}
      />
      {!givenNameIsValid && (
        <div className="valid-message">Lütfen Adınızı Giriniz</div>
      )}
    </div>
  );
};

export default GivenName;
