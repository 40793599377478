import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useValidCode, useValidPassword } from "../hooks/useAuthHooks";
import Code from "../components/auth/code";
import Password from "../components/auth/password";
import { AuthContext } from "../context/authContext";
import { useLoading } from "../libs/loadingContext";
import AlertSignInError from "../components/alertSignInError";

export default function ForgotPassword() {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const { code, setCode, codeIsValid } = useValidCode("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [verificationCode, setVerificationCode] = useState(false);
  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword("");
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { setLoading } = useLoading();
  const isValidCode = !codeIsValid || code.length === 0;
  const isValidPassword = !passwordIsValid || password.length === 0;

  const resetPassword = async () => {
    try {
      setLoading(true);
      if (password !== passwordConfirm) {
        setPasswordError(
          "Girmiş olduğunuz şifre ve şifre tekrar alanları eşleşmiyor."
        );
        return;
      }
      const username = localStorage.getItem("username");
      await authContext.forgotPassword(username, code, password);
      navigate("/", {
        state: {
          id: 1,
          type: "success",
          message: "Şifreniz başarıyla değiştirildi.",
        },
      });
    } catch (err) {
      if (
        err.message === "Invalid verification code provided, please try again."
      ) {
        setError("Geçersiz doğrulama kodu girildi, lütfen tekrar deneyin.");
        setVerificationCode(false);
      } else if (err instanceof Error) {
        setError("Geçersiz doğrulama kodu girildi, lütfen tekrar deneyin.");
        setVerificationCode(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerification = () => {
    setVerificationCode(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes, verificationCode]);

  useEffect(() => {
    if (password === passwordConfirm) {
      setPasswordError("");
    }
  }, [password, passwordConfirm]);

  useEffect(() => {
    setMinutes(1);
    setSeconds(59);
  }, [verificationCode]);

  return (
    <>
      <div className="content-page">
        {verificationCode ? (
          <>
            <h3 className="process-title forget-password">Şifremi Unuttum</h3>
            <Password
              passwordIsValid={passwordIsValid}
              setPassword={setPassword}
              label={"Şifre"}
              required={false}
              newPassword={true}
            />
            <Password
              passwordIsValid={passwordConfirmIsValid}
              setPassword={setPasswordConfirm}
              label={"Şifre tekrar"}
              required={false}
              newPassword={true}
            />
            {passwordError && (
              <div className="invalid-email">{passwordError}</div>
            )}
            <div className="forgot-password-request-btn">
              {isValidPassword ? (
                <button className="btn btn-default btn-default-disabled">
                  Şifre Değiştir
                </button>
              ) : (
                <button
                  className="btn btn-default"
                  onClick={() => resetPassword()}
                >
                  Şifre Değiştir
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <h3 className="process-title">Hesap Doğrulama</h3>
            <div className="process-desc">
              E-posta adresinize veya telefon numaranıza gelen kodu giriniz
            </div>
            {error && <AlertSignInError message={error} />}
            <Code code={code} setCode={setCode} codeIsValid={codeIsValid} />
            <div className="forgot-password-continue">
              {isValidCode ? (
                <button className="btn btn-default btn-default-disabled">
                  Devam Et
                </button>
              ) : (
                <button
                  className="btn btn-default"
                  onClick={() => handleVerification()}
                >
                  Devam Et
                </button>
              )}
            </div>
            <div
              style={{ position: "inherit", marginTop: "40px" }}
              className="confirm-code"
            >
              <div>
                Kod size ulaşmadı mı?{" "}
                {seconds > 0 || minutes > 0 ? (
                  <>
                    <span className="counter">
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds} sn içinde tekrar
                      gönderebilirsiniz.
                    </span>
                  </>
                ) : (
                  <>
                    <span onClick={() => navigate("/request-code?id=1")}>
                      Tekrar gönder
                    </span>
                  </>
                )}
              </div>
              <div>
                Farklı bir numara ile{" "}
                <span onClick={() => navigate("/request-code?id=1")}>
                  doğrulama yap
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
