import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useValidPassword, useValidUsername } from "../hooks/useAuthHooks";
import Password from "../components/auth/password";
import Username from "../components/auth/username";
import axios from "axios";
import { useLoading } from "../libs/loadingContext";

const Login = () => {
  const [searchParams] = useSearchParams();
  const { username, setUsername, usernameIsValid } = useValidUsername("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const [clientId, setClientId] = useState("");
  const [responseType, setResponseType] = useState("");
  const [scope, setScope] = useState("");
  const [redirectUri, setRedirectUri] = useState("");
  const { setLoading } = useLoading();

  const signInClicked = async () => {
    const data = {
      username: username,
      password: password,
      redirectUri: redirectUri,
      clientId: clientId,
      responseType: responseType,
      scope: scope,
    };
    const response_type = localStorage.getItem("response_type");
    try {
      setLoading(true);
      axios.post("https://testbackend.vrunibex.com/", data).then((response) => {
        window.location.href = `${redirectUri}?${response_type}=${response.data?.code}`;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const code = searchParams.get("code");
    if (code !== null) {
      const redirect_uri = localStorage.getItem("redirectUri");
      window.location.href = `${redirect_uri}?code=${code}`;
    } else {
      const client_id = searchParams.get("client_id");
      const response_type = searchParams.get("response_type");
      const scope_uri = searchParams.get("scope");
      const redirect_uri = searchParams.get("redirect_uri");
      localStorage.setItem("redirectUri", redirect_uri);
      localStorage.setItem("response_type", response_type);
      setClientId(client_id);
      setResponseType(response_type);
      setScope(scope_uri);
      setRedirectUri(redirect_uri);
    }
  }, [searchParams]);

  return (
    <>
      <div className="content-page">
        <div>
          <Username
            usernameIsValid={usernameIsValid}
            setUsername={setUsername}
          />
        </div>
        <div>
          <Password
            passwordIsValid={passwordIsValid}
            setPassword={setPassword}
            label={"Şifre"}
            required={false}
            newPassword={false}
          />
        </div>
        <div>
          <div className="forget">
            <div className="remember-me">
              <p>Beni Hatırla</p>
              <div>
                <div className="onoffswitch">
                  <input
                    type="checkbox"
                    name="onoffswitch"
                    className="onoffswitch-checkbox"
                    id="myonoffswitch"
                  />
                  <label
                    className="onoffswitch-label"
                    htmlFor="myonoffswitch"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="login-button">
          <button className="btn btn-default" onClick={signInClicked}>
            Giriş Yap
          </button>
        </div>
        <div className="form-social">
          <div className="social-login">
            <a
              className="social-provider"
              href={`https://hosted-identity.vrunibex.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=${process.env.REACT_APP_REDIRECT_URI_HOSTED_UI}&response_type=CODE&client_id=5iqt1h338hs81mu45q87105hb1&scope=aws.cognito.signin.user.admin email openid phone profile`}
            >
              <svg
                className="provider-background"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <circle cx="20" cy="20" r="20" fill="#4867AA" />
              </svg>
              <svg
                className="provider provider-facebook"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="24"
                viewBox="0 0 12 24"
                fill="none"
              >
                <g>
                  <path
                    d="M2.82641 17.9746C2.82641 16.0953 2.81901 14.2171 2.83275 12.3388C2.83486 12.0226 2.74608 11.9229 2.43111 11.9356C1.84767 11.9589 1.26318 11.9335 0.679748 11.9462C0.442992 11.9526 0.356322 11.8868 0.358436 11.6353C0.370062 10.4627 0.369006 9.29016 0.359493 8.11759C0.357379 7.89263 0.425024 7.81835 0.652267 7.82259C1.24944 7.83639 1.84767 7.80561 2.44379 7.83532C2.78941 7.8523 2.86023 7.72921 2.85389 7.40768C2.83275 6.51526 2.83275 5.62177 2.85283 4.72934C2.87608 3.63954 3.1678 2.6219 3.81465 1.73584C4.63167 0.615269 5.79431 0.102735 7.13664 0.0422492C8.51489 -0.0192975 9.89632 0.0146593 11.2767 -0.000196784C11.4691 -0.00231908 11.5261 0.0666556 11.5261 0.254479C11.5198 1.43978 11.5187 2.62615 11.5261 3.81145C11.5282 4.02262 11.4595 4.08735 11.2534 4.0831C10.5897 4.07249 9.92592 4.0778 9.26321 4.07992C8.37961 4.0831 7.85113 4.5394 7.79617 5.41697C7.75178 6.12051 7.78454 6.82829 7.76763 7.53502C7.76129 7.78651 7.91032 7.76953 8.07626 7.76953C9.08459 7.76847 10.094 7.77908 11.1023 7.76211C11.3982 7.7568 11.4955 7.82577 11.4617 8.13775C11.3348 9.31563 11.226 10.4956 11.1256 11.6767C11.1023 11.943 10.9818 11.994 10.7429 11.9897C9.94706 11.977 9.15118 11.9812 8.35424 11.9865C7.78243 11.9897 7.83845 11.9027 7.83845 12.4885C7.83528 16.1653 7.83105 19.8433 7.8469 23.5202C7.84902 23.9085 7.74966 24.0051 7.36705 23.9977C6.00042 23.9722 4.63273 23.9711 3.2661 23.9977C2.88137 24.0051 2.81795 23.8746 2.82112 23.5297C2.83592 21.678 2.82852 19.8263 2.82852 17.9735L2.82641 17.9746Z"
                    fill="#FEFEFE"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14_131">
                    <rect
                      width="11.1677"
                      height="24"
                      fill="white"
                      transform="translate(0.358398)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              className="social-provider"
              href={`https://hosted-identity.vrunibex.com/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_REDIRECT_URI_HOSTED_UI}&response_type=CODE&client_id=5iqt1h338hs81mu45q87105hb1&scope=aws.cognito.signin.user.admin email openid phone profile`}
            >
              <svg
                className="provider-background"
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
              >
                <circle cx="20.8384" cy="20" r="20" fill="#DF4D35" />
              </svg>
              <svg
                className="provider provider-google"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <g>
                  <path
                    d="M24.6013 10.0244H13.1116V14.4702H19.7411C19.321 15.9567 18.4911 17.2165 17.2319 18.0672C16.1346 18.8086 14.7113 19.2396 12.9495 19.2396C9.80167 19.2396 7.13103 17.1608 6.19237 14.2791C5.95881 13.5625 5.8324 12.7963 5.8324 12C5.8324 11.2037 5.95881 10.4376 6.19237 9.72104C7.13103 6.8392 9.80151 4.76043 12.9495 4.76043C14.8789 4.76043 16.5266 5.43216 17.7492 6.54124L21.1884 3.21802C19.1363 1.2214 16.2991 0 12.9495 0C8.34176 0 4.18233 2.68723 2.14183 6.60497C1.29707 8.2269 0.81543 10.0597 0.81543 12C0.81543 13.9403 1.29707 15.7731 2.14167 17.395C4.18233 21.3128 8.34176 24 12.9495 24C16.5402 24 19.2823 22.9202 21.2152 21.1313C23.0426 19.4402 24.1465 17.1152 24.5598 14.4702C24.6844 13.673 24.7462 12.8467 24.7462 12C24.7462 11.327 24.697 10.6672 24.6013 10.0244Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14_124">
                    <rect
                      width="23.9308"
                      height="24"
                      fill="white"
                      transform="translate(0.81543)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              className="social-provider"
              href={`https://hosted-identity.vrunibex.com/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=${process.env.REACT_APP_REDIRECT_URI_HOSTED_UI}&response_type=CODE&client_id=5iqt1h338hs81mu45q87105hb1&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile`}
            >
              <svg
                className="provider-apple"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <circle cx="20" cy="20" r="20" fill="#4E4E4E" />
                <path
                  d="M20.9336 9.28852C22.4767 7.21032 24.6217 7.20007 24.6217 7.20007C24.6217 7.20007 24.9408 9.15407 23.408 11.0367C21.7711 13.0467 19.9108 12.7179 19.9108 12.7179C19.9108 12.7179 19.5617 11.1372 20.9336 9.28891V9.28852Z"
                  fill="white"
                />
                <path
                  d="M20.1067 14.0863C20.9007 14.0863 22.3736 12.9713 24.2914 12.9713C27.5923 12.9713 28.8908 15.3716 28.8908 15.3716C28.8908 15.3716 26.3512 16.6987 26.3512 19.9187C26.3512 23.5511 29.515 24.803 29.515 24.803C29.515 24.803 27.3033 31.1649 24.3161 31.1649C22.9438 31.1649 21.8775 30.2198 20.4316 30.2198C18.9856 30.2198 17.4965 31.2 16.5443 31.2C13.8164 31.2004 10.3701 25.1657 10.3701 20.3146C10.3701 15.4635 13.2871 13.0383 16.0231 13.0383C17.8016 13.0383 19.182 14.0867 20.1067 14.0867V14.0863Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
