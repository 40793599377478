const FamilyName = ({
  familyNameIsValid,
  setFamilyName,
  familyName,
  label,
  required,
}) => {
  return (
    <div className="form-group">
      <label>
        {label}
        {required && <span>*</span>}
      </label>
      <input
        type="text"
        value={familyName}
        onChange={(evt) => {
          setFamilyName(evt.target.value);
        }}
      />
      {!familyNameIsValid && (
        <div className="valid-message">Lütfen Soyadınızı Giriniz</div>
      )}
    </div>
  );
};

export default FamilyName;
