import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import vestelKimlik from "../assets/images/vestelKimlik.png";
import vestelKimlik1 from "../assets/images/vestelKimlik1.png";
import vestelKimlik2 from "../assets/images/vestelKimlik2.png";
import vestelKimlik3 from "../assets/images/vestelKimlik3.png";
import vestelKimlik4 from "../assets/images/vestelKimlik4.png";
import vestelKimlik5 from "../assets/images/vestelKimlik5.png";
import vestelKimlik6 from "../assets/images/vestelKimlik6.png";
import devamEtIcon from "../assets/images/devamEtIcon.png";
import useMediaQuery from "../components/useMediaQuery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function VestelIdentity() {
const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:785px)");
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    const page = searchParams.get("page");
    if (page === "forgetPassword") {
        setRedirectUrl("/request-code?id=1")
    } else {
        setRedirectUrl("/sign-up?id=1")
    }
  });

  return (
    <div className="identity-page">
      <div className="content-page identity-content-page">
        <div className="identity-overflow">
          <img
            className="vestel-kimlik-img"
            src={vestelKimlik}
            alt="vestel-kimlik"
          />
          <div>
            <div className="identity-position">VESTEL KİMLİK</div>
            <div className="identity-absolute">Birçok marka tek hesap</div>
          </div>
        </div>
        <div className="identity-display">
          {isSmallScreen ? (
            <Carousel>
              <div>
                <img
                  style={{ width: "auto" }}
                  src={vestelKimlik1}
                  alt="vestel-kimlik1"
                />
                <p className="identity-p">
                  Vestel Kimlik ile Vestel’in dijital dünyasında yeni bir dönem
                  başlıyor!
                </p>
                <p className="identity-p-size">
                  Devreye aldığımız “Tek hesap” çözümü sayesinde, her üyelikte
                  yeniden kimlik doğrulamaya ihtiyaç duymadan birden fazla
                  uygulamaya erişmenize olanak tanıyoruz.
                </p>
                <p className="identity-p-size1">
                  Bu sayede, Vestel ve diğer markalarımıza ait websiteleri ile
                  Vestel uygulamalarından her hangi birine üye olduğunuz anda,
                  artık aynı kullanıcı adı ve şifre ile tüm Vestel
                  platformlarına anında erişebilirsiniz.
                </p>
              </div>
              <div>
                <img
                  style={{ width: "auto" }}
                  src={vestelKimlik2}
                  alt="vestel-kimlik1"
                />
                <p className="identity-p">Hayatınızı Kolaylaştıran</p>
                <p className="identity-p-font">Tek Bir Hesap</p>
                <p className="identity-p-size">
                  Artık tek bir oturum açma işlemiyle tüm Vestel platformlara
                  anında erişebilirsiniz!
                </p>
                <p className="identity-p-size1">
                  Vestel, Regal, VS Outlet websiteleri ve Vestel Akıllı Yaşam,
                  Vestel Akıllı Sağlık mobil uygulamalarımıza tek bir hesapla
                  ulaşabilir, dijital dünyada yolculuğunuzu daha da kolay, hızlı
                  ve pratik hale getirebilirsiniz.
                </p>
              </div>
              <div>
                <img
                  style={{ width: "auto" }}
                  src={vestelKimlik3}
                  alt="vestel-kimlik1"
                />
                <p className="identity-p">Şifreleri Hatırlamakla Vedalaşın</p>
                <p className="identity-p-size">
                  Size özel bir dijital Vestel Kimlik hesabı oluşturarak, her
                  bir platform için ayrı ayrı şifre hatırlamayı sona
                  erdiriyoruz. Artık şifrelerle uğraşmak yerine tek bir şifre
                  ile her yere erişim sağlayabilirsiniz.
                </p>
              </div>
              <div>
                <img
                  style={{ width: "auto" }}
                  src={vestelKimlik4}
                  alt="vestel-kimlik1"
                />
                <p className="identity-p">Güvenlikte Yeni Bir Dönem</p>
                <p className="identity-p-size">
                  Güvenlik, en üst önceliğimizdir. Vestel Kimlik, hesap
                  bilgilerinizi ve kişisel verilerinizi güçlü güvenlik
                  önlemleriyle korur. Tek Oturum Açma (SSO) mekanizması, kişisel
                  bilgilerinizi korumak için güçlü önlemler kullanır.
                  Verileriniz, gelişmiş güvenlik katmanları arkasında korunur,
                  böylece endişesiz bir dijital deneyim yaşarsınız. E-posta ve
                  telefon bilgilerinizi üyelik adımında doğrulayarak kaydeder,
                  yalnızca size özel bir Vestel Kimlik yaratırsınız.
                </p>
              </div>
              <div>
                <img
                  style={{ width: "auto" }}
                  src={vestelKimlik5}
                  alt="vestel-kimlik1"
                />
                <p className="identity-p">
                  Tek Şifre ile Giriş Deneyimini Yaşayın
                </p>
                <p className="identity-p-size">
                  Şifrenizi unutmanız halinde yeni şifre belirlemeye gerek
                  kalmadan, kayıtlı cep telefonunuza SMS ile gelen tek
                  kullanımlık şifre ile tüm Vestel dünyasına hızlı giriş
                  yapabilirsiniz.
                </p>
              </div>
              <div>
                <img
                  style={{ width: "auto" }}
                  src={vestelKimlik6}
                  alt="vestel-kimlik1"
                />
                <p className="identity-p">Markalar ve Uygulamalar Arasında</p>
                <p className="identity-p-font">Sürtünmesiz Geçiş</p>
                <p className="identity-p-size">
                  Tek bir tıklama ile Vestel markalarının websiteleri ve tercih
                  ettiğiniz mobil uygulamalar arasında zahmetsiz bir şekilde
                  geçiş yapabilirsiniz. Tek Oturum Açma (SSO) özelliği ile,
                  anında geçişleri kolaylaştırarak size kesintisiz ve etkileyici
                  bir deneyim sunuyoruz.
                </p>
              </div>
            </Carousel>
          ) : (
            <>
              <div className="identity-float">
                <div>
                  <img src={vestelKimlik1} alt="vestel-kimlik1" />
                </div>
                <p className="identity-p">
                  Vestel Kimlik ile Vestel’in dijital dünyasında yeni bir dönem
                  başlıyor!
                </p>
                <p className="identity-p-size">
                  Devreye aldığımız “Tek hesap” çözümü sayesinde, her üyelikte
                  yeniden kimlik doğrulamaya ihtiyaç duymadan birden fazla
                  uygulamaya erişmenize olanak tanıyoruz.
                </p>
                <p className="identity-p-size1">
                  Bu sayede, Vestel ve diğer markalarımıza ait websiteleri ile
                  Vestel uygulamalarından her hangi birine üye olduğunuz anda,
                  artık aynı kullanıcı adı ve şifre ile tüm Vestel
                  platformlarına anında erişebilirsiniz.
                </p>

                <div className="identity-div-size1">
                  <img src={vestelKimlik3} alt="vestel-kimlik1" />
                </div>
                <p className="identity-p">Şifreleri Hatırlamakla Vedalaşın</p>
                <p className="identity-p-size">
                  Size özel bir dijital Vestel Kimlik hesabı oluşturarak, her
                  bir platform için ayrı ayrı şifre hatırlamayı sona
                  erdiriyoruz. Artık şifrelerle uğraşmak yerine tek bir şifre
                  ile her yere erişim sağlayabilirsiniz.
                </p>
                <div className="identity-div-size1">
                  <img src={vestelKimlik4} alt="vestel-kimlik1" />
                </div>
                <p className="identity-p">Güvenlikte Yeni Bir Dönem</p>
                <p className="identity-p-size">
                  Güvenlik, en üst önceliğimizdir. Vestel Kimlik, hesap
                  bilgilerinizi ve kişisel verilerinizi güçlü güvenlik
                  önlemleriyle korur. Tek Oturum Açma (SSO) mekanizması, kişisel
                  bilgilerinizi korumak için güçlü önlemler kullanır.
                  Verileriniz, gelişmiş güvenlik katmanları arkasında korunur,
                  böylece endişesiz bir dijital deneyim yaşarsınız. E-posta ve
                  telefon bilgilerinizi üyelik adımında doğrulayarak kaydeder,
                  yalnızca size özel bir Vestel Kimlik yaratırsınız.
                </p>
              </div>
              <div className="identity-div-right">
                <div>
                  <img src={vestelKimlik2} alt="vestel-kimlik2" />
                </div>
                <p className="identity-p">Hayatınızı Kolaylaştıran</p>
                <p className="identity-p-font">Tek Bir Hesap</p>
                <p className="identity-p-size">
                  Artık tek bir oturum açma işlemiyle tüm Vestel platformlara
                  anında erişebilirsiniz!
                </p>
                <p className="identity-p-size1">
                  Vestel, Regal, VS Outlet websiteleri ve Vestel Akıllı Yaşam,
                  Vestel Akıllı Sağlık mobil uygulamalarımıza tek bir hesapla
                  ulaşabilir, dijital dünyada yolculuğunuzu daha da kolay, hızlı
                  ve pratik hale getirebilirsiniz.
                </p>

                <div className="identity-div-padding">
                  <img src={vestelKimlik5} alt="vestel-kimlik1" />
                </div>
                <p className="identity-p">
                  Tek Şifre ile Giriş Deneyimini Yaşayın
                </p>
                <p className="identity-p-size">
                  Şifrenizi unutmanız halinde yeni şifre belirlemeye gerek
                  kalmadan, kayıtlı cep telefonunuza SMS ile gelen tek
                  kullanımlık şifre ile tüm Vestel dünyasına hızlı giriş
                  yapabilirsiniz.
                </p>
                <div className="identity-div-padding1">
                  <img src={vestelKimlik6} alt="vestel-kimlik1" />
                </div>
                <p className="identity-p">Markalar ve Uygulamalar Arasında</p>
                <p className="identity-p-font">Sürtünmesiz Geçiş</p>
                <p className="identity-p-size">
                  Tek bir tıklama ile Vestel markalarının websiteleri ve tercih
                  ettiğiniz mobil uygulamalar arasında zahmetsiz bir şekilde
                  geçiş yapabilirsiniz. Tek Oturum Açma (SSO) özelliği ile,
                  anında geçişleri kolaylaştırarak size kesintisiz ve etkileyici
                  bir deneyim sunuyoruz.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      {isSmallScreen ? (
        <div className="identity-div-margin">
          <p className="identity-p-padding">Şimdi,</p>
          <p className="identity-p-padding1">
            Vestel Kimlik oluşturma adımlarına devam edebilirsiniz.
          </p>
          <button
            className="identity-button"
            onClick={() => (window.location.href = redirectUrl)}
          >
            Devam et{" "}
            <img
              style={{
                paddingLeft: "15px",
              }}
              src={devamEtIcon}
              alt="devam-et"
            />
          </button>
        </div>
      ) : (
        <>
          <div className="identity-div-margin">
            <p className="identity-p-padding">Şimdi,</p>
            <p className="identity-p-padding1">
              Vestel Kimlik oluşturma adımlarına devam edebilirsiniz.
            </p>
          </div>
          <div className="identity-div-text">
            <button
              className="identity-button"
              onClick={() => (window.location.href = redirectUrl)}
            >
              Devam et{" "}
              <img
                style={{
                  paddingLeft: "15px",
                }}
                src={devamEtIcon}
                alt="devam-et"
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
}
