import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useValidCode, useValidPhoneNumber } from "../hooks/useAuthHooks";
import PhoneNumber from "../components/auth/phoneNumber";
import Code from "../components/auth/code";
import { AuthContext } from "../context/authContext";
import Keyboard from "../components/keyboard";
import VerifiedSuccess from "../components/verifiedSuccess";
import { useLoading } from "../libs/loadingContext";
import successAlertIcon from "../assets/images/successAlertIcon.svg";
import AlertError from "../components/alertError";
import { ErrorDefinition } from "../helpers/const";

const STEP = {
  phoneConfirmCode: "phoneConfirmCode",
  getPhoneNumber: "getPhoneNumber",
};

const ConfirmAttribute = () => {
  const [flag, setFlag] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [count, setCount] = useState(0);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState(false);
  const [phoneCodeNumber, setPhoneCodeNumber] = useState();
  const [codeAgain, setCodeAgain] = useState(false);
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [step, setStep] = useState("");
  const {
    phoneNumber,
    setPhoneNumber,
    phoneNumberIsValid,
    countryCode,
    setCountryCode,
  } = useValidPhoneNumber("");
  const { setLoading } = useLoading();

  const { code: phoneCode, setCode: setPhoneCode } = useValidCode("");

  const isValid = !phoneNumberIsValid || phoneNumber.length === 0;

  const handleSendPhoneCode = async () => {
    try {
      setLoading(true);
      await authContext.getAttributeVerificationCode("phone_number");
      if (count === 1) {
        setFlag(true);
      } else {
        setCount(1);
      }
      setMinutes(1);
      setSeconds(59);
      setStep(STEP.phoneConfirmCode);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const signOutClicked = async () => {
    try {
      setLoading(true);
      await authContext.signOut();
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickSendPhoneNumberCode = async () => {
    try {
      setLoading(true);
      const phone = countryCode.dial_code + phoneNumber;
      const attr = {
        Name: "phone_number",
        Value: phone,
      };
      await authContext.setAttribute(attr);
      setPhoneCodeNumber(phone);
      if (refreshState) {
        setRefreshState(false);
        window.location.reload();
      }
      await authContext.getAttributeVerificationCode("phone_number");
      setStep(STEP.phoneConfirmCode);
      setFlag(true);
      setMinutes(1);
      setSeconds(59);
    } catch (error) {
      //alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickAgainCode = () => {
    if (codeAgain) {
      handleSendPhoneCode();
    } else {
      handleClickSendPhoneNumberCode();
    }
  };

  const handleClickConfirmPhoneCode = async () => {
    try {
      setLoading(true);
      await authContext.verifyAttribute(phoneCode, "phone_number");
      await authContext.refreshSession();
      setVerifiedPhoneNumber(true);
      setTimeout(function () {
        window.location.href = "/redirect";
      }, 5000);
    } catch (err) {
      if (err.message === ErrorDefinition.ConfirmAttributeCodeError) {
        setErrorMessage(ErrorDefinition.ConfirmAttributeCodeErrorMessage);
      }
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!authContext.attrInfo?.length) return;

    const username = authContext.attrInfo.find((attr) => {
      return attr.Name === "email";
    });
    localStorage.setItem("username", username?.Value);

    const phoneVerified = authContext.attrInfo.find((attr) => {
      return attr.Name === "phone_number_verified";
    });

    const phoneNumber = authContext.attrInfo.find((attr) => {
      return attr.Name === "phone_number";
    });

    if (phoneNumber === undefined) {
      setRefreshState(true);
      setStep(STEP.getPhoneNumber);
      return;
    }

    if (phoneVerified === undefined) {
      setPhoneNumberVerified(true);
      setRefreshState(true);
      setStep(STEP.getPhoneNumber);
      return;
    }

    if (phoneVerified?.Value === "false") {
      setPhoneNumberVerified(true);
      setPhoneNumber(phoneNumber.Value);
      setStep(STEP.phoneConfirmCode);
      return;
    }
    navigate("/");
    // eslint-disable-next-line
  }, [authContext, history]);

  useEffect(() => {
    if (phoneNumberVerified) {
      handleSendPhoneCode();
    }
    // eslint-disable-next-line
  }, [phoneNumberVerified]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  useEffect(() => {
    setPhoneCodeNumber(countryCode.dial_code + phoneNumber);
    setCodeAgain(true);
    // eslint-disable-next-line
  }, [phoneNumber]);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 3000);
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      setFlag(false);
    }, 3000);
  }, [flag]);

  const alertSuccess = (
    <>
      {flag && (
        <div className="alert alert-top">
          <div className="alert-success">
            <div className="alert-icon">
              <img src={successAlertIcon} alt="success-alert-icon" />
            </div>
            <div className="alert-message">İşlem Başarılı.</div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      {!verifiedPhoneNumber ? (
        <>
          <div className="content-page">
            <h3 className="process-title">Cep Telefonu Numarası Ekle</h3>
            {step === STEP.getPhoneNumber && (
              <>
                <div className="process-desc">
                  Hesap güvenliğinizi artırmak için cep telefonu numaranızı
                  ekleyin ve doğrulayın.
                </div>
                <div>
                  <PhoneNumber
                    phoneNumberIsValid={phoneNumberIsValid}
                    setPhoneNumber={setPhoneNumber}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    label={"Yeni Cep Telefonu Numarası"}
                    required={false}
                  />
                </div>
                <div className="phone-confirm-desc"></div>
                <div>
                  {isValid ? (
                    <button className="btn btn-default btn-default-disabled">
                      Cep Telefonu Numarası Ekle
                    </button>
                  ) : (
                    <button
                      className="btn btn-default"
                      onClick={handleClickSendPhoneNumberCode}
                    >
                      Cep Telefonu Numarası Ekle
                    </button>
                  )}
                </div>
                <div
                  className="confirm-code confirm-code-mb"
                  style={{ position: "inherit", marginTop: "30px" }}
                >
                  <div style={{ marginTop: "30px" }}>
                    <span
                      style={{ display: "inline" }}
                      onClick={signOutClicked}
                    >
                      Çıkış Yap
                    </span>
                  </div>
                </div>
              </>
            )}
            {step === STEP.phoneConfirmCode && (
              <>
                {alertSuccess}
                <div className="process-desc">
                  {phoneCodeNumber.replace("+90+90", "+90")} nolu telefonunuza
                  SMS ile gelen güvenlik kodunu girin
                </div>
                <div className="phone-confirm-code-input">
                  <Code code={phoneCode} setCode={setPhoneCode} />
                </div>
                <div className="confirm-code-btn">
                  <button
                    className="btn btn-default"
                    onClick={handleClickConfirmPhoneCode}
                  >
                    Devam Et
                  </button>
                </div>
                <div className="numpad">
                  <Keyboard code={phoneCode} setCode={setPhoneCode} />
                </div>

                <div
                  className="confirm-code confirm-code-mb"
                  style={{ position: "inherit", marginTop: "30px" }}
                >
                  <div>
                    Kod size ulaşmadı mı?{" "}
                    {seconds > 0 || minutes > 0 ? (
                      <>
                        <span className="counter">
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds} sn içinde
                          tekrar gönderebilirsiniz.
                        </span>
                      </>
                    ) : (
                      <>
                        <span onClick={handleClickAgainCode}>
                          Tekrar gönder
                        </span>
                      </>
                    )}
                  </div>
                  <div>
                    Farklı bir numara ile{" "}
                    <span onClick={() => setStep(STEP.getPhoneNumber)}>
                      doğrulama yap
                    </span>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <span
                      style={{ display: "inline" }}
                      onClick={signOutClicked}
                    >
                      Çıkış Yap
                    </span>
                  </div>
                </div>
                {error?.length > 0 && (
                  <div className="profile-change-alert">
                    <AlertError message={errorMessage} />
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <VerifiedSuccess />
        </>
      )}
    </>
  );
};

export default ConfirmAttribute;
