import { useEffect, useState } from "react";
import errorAlertIcon from "../assets/images/errorAlertIcon.svg";

function AlertError({ message }) {
  const [isShow, setIsShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsShow(false);
    }, 3000);
  }, []);
  return (
    <>
      {isShow && (
        <div className="alert">
          <div className="alert-error">
            <div className="alert-header">
              <div className="alert-icon">
                <img src={errorAlertIcon} alt="success-alert-icon" />
              </div>
              {message?.length > 0 ? (
                <div className="alert-message">{message}</div>
              ) : (
                <div className="alert-message">Bir Sorun Oluştu</div>
              )}
            </div>
            <div className="alert-body"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default AlertError;
