import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useValidPhoneNumber, useValidCode } from "../hooks/useAuthHooks";
import PhoneNumber from "../components/auth/phoneNumber";
import Code from "../components/auth/code";
import { AuthContext } from "../context/authContext";
import { useLoading } from "../libs/loadingContext";
import { toast } from "react-toastify";

function OtpLogin() {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);
  const [error, setError] = useState("");
  const [codeCounter, setCodeCounter] = useState(0);
  const [btnFlag, setBtnFlag] = useState(false);
  const [created, setCreated] = useState(false);
  const [errFlag, setErrFlag] = useState(false);
  const { setLoading } = useLoading();
  const {
    phoneNumber,
    setPhoneNumber,
    phoneNumberIsValid,
    countryCode,
    setCountryCode,
  } = useValidPhoneNumber("");
  const { code, setCode } = useValidCode("");
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const isValid = !phoneNumberIsValid || phoneNumber.length === 0;

  const handleSignInCode = async () => {
    try {
      setLoading(true);
      setCode("");
      if (codeCounter < 5) {
        setCodeCounter(codeCounter + 1);
        setMinutes(0);
        setSeconds(59);
        setBtnFlag(false);
        setError("");
        await authContext.initiateAuth(countryCode.dial_code + phoneNumber);
        setCreated(true);
        toast.success("İşlem Başarılı. ");
      } else {
        setError("Daha fazla kod isteyemezsiniz.");
        setBtnFlag(false);
      }
    } catch (err) {
      setCreated(true);
      setErrFlag(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async () => {
    try {
      setLoading(true);
      const tempCode = code;
      setCode("");
      await authContext.answerChallenge(tempCode);
      navigate("/confirm-attribute");
      navigate(0);
    } catch (err) {
      if (errFlag) {
        setError(
          "Girmiş olduğunuz telefon numarası veya doğrulama kodu hatalıdır"
        );
      } else {
        setMinutes(0);
        setSeconds(59);
        setError(
          "Yanlış Kod Girdiniz. Telefonunuza tekrardan yeni kod gönderdik."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setError("");
  }, [phoneNumber, code]);

  useEffect(() => {
    if (created) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, minutes, created]);

  useEffect(() => {
    setMinutes(0);
    setSeconds(59);
  }, [created]);

  const phoneLogin = (
    <>
      <div className="popup-otp">
        <h3 className="popup-otp-title">SMS ile Giriş</h3>
        <div className="popup-otp-desc">
          Lütfen kayıtlı cep telefonu numaranızı giriniz. Telefonunuza gelecek
          tek kullanımlık şifre ile giriş yapabilirsiniz.
        </div>
        <PhoneNumber
          phoneNumberIsValid={phoneNumberIsValid}
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          label={"Telefon"}
          required={false}
        />
        {error && <div className="otp-valid-message">{error}</div>}
        <div>
          {isValid ? (
            <button className="btn btn-default btn-default-disabled">
              Gönder
            </button>
          ) : (
            <button
              className="btn btn-default"
              onClick={() => handleSignInCode()}
            >
              Gönder
            </button>
          )}
        </div>
      </div>
    </>
  );
  const phoneLoginConfirm = (
    <>
      <div className="popup-otp">
        <h3 className="popup-otp-title confirm-otp-title">
          Telefonunuza gelen onay kodunu giriniz.
        </h3>
        <div className="otp-code">
          <Code code={code} setCode={setCode} />
        </div>
        {error && <div className="otp-valid-message">{error}</div>}
        {seconds > 0 || minutes > 0 ? (
          <>
            <br></br>
            <span className="counter">
              {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds} sn içinde tekrar
              gönderebilirsiniz.
            </span>
          </>
        ) : (
          <>{!btnFlag ? setBtnFlag(true) : null}</>
        )}
        <div className="btn-otp-confirm">
          <button className="btn btn-default" onClick={() => handleSignIn()}>
            Onayla
          </button>
        </div>
        <div className="btn-otp-confirm">
          {!btnFlag ? (
            <button className="btn btn-default btn-default-disabled">
              Yeniden Gönder
            </button>
          ) : (
            <button
              className="btn btn-default"
              onClick={() => handleSignInCode()}
            >
              Yeniden Gönder
            </button>
          )}
        </div>
      </div>
    </>
  );
  return <>{created ? phoneLoginConfirm : phoneLogin}</>;
}

export default OtpLogin;
