import { useContext } from "react";
import { useValidGivenName, useValidFamilyName } from "../hooks/useAuthHooks";
import GivenName from "../components/auth/givenName";
import FamilyName from "../components/auth/familyName";
import { AuthContext } from "../context/authContext";
import { useLoading } from "../libs/loadingContext";

export default function ChangeProfile() {
  const { givenName, setGivenName, givenNameIsValid } = useValidGivenName("");
  const { familyName, setFamilyName, familyNameIsValid } =
    useValidFamilyName("");
  const authContext = useContext(AuthContext);
  const { setLoading } = useLoading();

  const changeProfile = async () => {
    try {
      setLoading(true);
      await authContext.setProfileAttribute(givenName, familyName);
      await authContext.refreshSession();
      localStorage.setItem("profileUpdateSuccessMessage", "İşlem başarılı.");
      window.location.href = "/";
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="content-page">
        <h3 className="process-title">Adınızı Değiştirin</h3>
        <div className="profile-update-form">
          <GivenName
            givenNameIsValid={givenNameIsValid}
            setGivenName={setGivenName}
            label={"Yeni Ad"}
            required={false}
          />
          <FamilyName
            familyNameIsValid={familyNameIsValid}
            setFamilyName={setFamilyName}
            label={"Yeni Soyad"}
            required={false}
          />
        </div>
        <div className="change-save">
          <button className="btn btn-default" onClick={() => changeProfile()}>
            Değişiklikleri Kaydet
          </button>
        </div>
      </div>
    </>
  );
}
