import VerificationInput from "react-verification-input";

const Code = ({ code, setCode, codeIsValid }) => {
  return (
    <>
      <div className="code-input">
        <VerificationInput
          value={code}
          placeholder={""}
          autoFocus={true}
          onChange={(e) => setCode(e)}
          classNames={{
            container: "container-code-input",
            character: "character",
            characterInactive: "character-inactive",
            characterSelected: "character-selected",
          }}
          length={6}
        />
      </div>
    </>
  );
};

export default Code;
