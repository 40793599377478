import { Routes, Route, Navigate } from "react-router-dom";
import AuthProvider, {
  AuthIsNotSignedIn,
  AuthIsSignedIn,
} from "./context/authContext";
import SignIn from "./pages/signIn";
import SignUp from "./pages/signUp";
import RequestCode from "./pages/requestCode";
import ForgotPassword from "./pages/forgotPassword";
import ChangePassword from "./pages/changePassword";
import ConfirmAttribute from "./pages/confirmAttribute";
import ChangeEmail from "./pages/changeEmail";
import Home from "./pages/home";
import ConfirmUser from "./pages/confirmUser";
import ChangePhone from "./pages/changePhone";
import ChangeProfile from "./pages/changeProfile";
import Login from "./pages/login";
import Communication from "./pages/communication";
import Support from "./pages/support";
import UserDelete from "./pages/userDelete";
import VestelIdentity from "./pages/vestelIdentity";
import Redirect from "./pages/redirect"

const SignInRoute = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/request-code" element={<RequestCode />} />
      <Route path="/vestel-identity" element={<VestelIdentity />} />
      <Route path="/confirmuser" element={<ConfirmUser />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/support" element={<Support />} />
      <Route path="/user-delete" element={<UserDelete />} />
      <Route path="/" element={<SignIn />} />
      <Route path="*" element={<Navigate to="/" />}></Route>
    </Routes>
  );
};

const MainRoute = () => (
  <Routes>
    <Route path="/change-password" element={<ChangePassword />} />
    <Route path="/change-email" element={<ChangeEmail />} />
    <Route path="/change-phone" element={<ChangePhone />} />
    <Route path="/change-profile" element={<ChangeProfile />} />
    <Route path="/confirm-attribute" element={<ConfirmAttribute />} />
    <Route path="/redirect" element={<Redirect />} />
    <Route path="/communication" element={<Communication />} />
    <Route path="/support" element={<Support />} />
    <Route path="/user-delete" element={<UserDelete />} />
    <Route path="/" element={<Home />} />
    <Route path="*" element={<Navigate to="/" />}></Route>
  </Routes>
);

export const AppRoute = () => {
  return (
    <AuthProvider>
      <AuthIsSignedIn>
        <MainRoute />
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <SignInRoute />
      </AuthIsNotSignedIn>
    </AuthProvider>
  );
};
