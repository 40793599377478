import Select from "react-select";
import { phoneCode } from "../../services/phoneCode";
import { PatternFormat } from "react-number-format";

const PhoneNumber = ({
  phoneNumberIsValid,
  setPhoneNumber,
  phoneNumber,
  value,
  countryCode,
  setCountryCode,
  label,
  required,
}) => {
  const changeCountry = (item) => {
    setCountryCode({
      name: item.code + " " + item.dial_code,
      dial_code: item.dial_code,
    });
  };
  const customStyles = {
    option: (base, { isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#f8f8f8" : isSelected ? "#f8f8f8" : "",
        color: isFocused ? "#000" : "#000",
      };
    },
    menu: (base) => ({
      ...base,
      width: "200px",
      minWidth: "100%",
      fontFamily: '"League Regular", sans-serif',
      borderRadius: "none",
    }),
  };
  return (
    <>
      <div className="form-group">
        <label>
          {label}
          {required && <span>*</span>}
        </label>
        <div style={{ display: "flex" }}>
          <div className="select">
            <Select
              isDisabled={true}
              options={phoneCode}
              defaultValue={countryCode}
              value={countryCode}
              isSearchable={false}
              onChange={changeCountry}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.dial_code}
              styles={customStyles}
            />
          </div>
          <PatternFormat
            placeholder="(5__) ___ __ __"
            className="phone"
            mask="_"
            format="(###) ### ####"
            value={value === undefined ? phoneNumber : value}
            onValueChange={({ floatValue }) => {
              const isValid = /^\d{0,10}$/.test(floatValue);
              if (isValid) {
                setPhoneNumber(floatValue);
              }
            }}
          />
        </div>
        {!phoneNumberIsValid && (
          <div className="valid-message">
            Girdiğiniz telefon numarası geçersiz. Lütfen geçerli bir telefon
            numarası giriniz.
          </div>
        )}
      </div>
    </>
  );
};

export default PhoneNumber;
