import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useValidPassword } from "../hooks/useAuthHooks";
import Password from "../components/auth/password";
import { AuthContext } from "../context/authContext";
import { useLoading } from "../libs/loadingContext";

export default function ChangePassword() {
  const [error, setError] = useState("");
  const {
    password: oldPassword,
    setPassword: setOldPassword,
    passwordIsValid: oldPasswordIsValid,
  } = useValidPassword("");
  const {
    password: newPassword,
    setPassword: setNewPassword,
    passwordIsValid: newPasswordIsValid,
  } = useValidPassword("");
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { setLoading } = useLoading();

  const changePassword = async () => {
    try {
      setLoading(true);
      await authContext.changePassword(oldPassword, newPassword);
      signOut();
    } catch (err) {
      if (err.message === "Incorrect username or password.") {
        setError("Girmiş olduğunuz eski şifre hatalıdır.");
      }
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      await authContext.signOut();
      navigate("/", {
        state: {
          id: 2,
          type: "success",
          message: "Şifreniz başarıyla değiştirildi.",
        },
      });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  return (
    <>
      <div className="content-page">
        <Password
          passwordIsValid={oldPasswordIsValid}
          setPassword={setOldPassword}
          label={"Eski Şifre"}
          required={false}
          newPassword={false}
          visibleValidMessage={false}
        />
        {<div className="invalid-email">{error}</div>}
        <Password
          passwordIsValid={newPasswordIsValid}
          setPassword={setNewPassword}
          label={"Yeni Şifre"}
          required={false}
          newPassword={true}
        />
        <div className="forgot-password-request-btn">
          <button className="btn btn-default" onClick={() => changePassword()}>
            Şifre Değiştir
          </button>
        </div>
      </div>
    </>
  );
}
