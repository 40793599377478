import { useState } from "react";
import liveSupport from "../assets/images/liveSupport.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import message from "../assets/images/mail.png";
import minus from "../assets/images/accordionMinus.svg";
import plus from "../assets/images/accordionPlus.svg";

const data = [
  {
    question: "1. Vestel Kimlik nedir?",
    answer: `<p>Vestel, Regal, VSOutlet, Vestel Çözümü Var websiteleri ile Evin Aklı, VFit+ uygulamalarına
      tek hesap ile giriş yapmanızı sağlayan, hızlı ve güvenilir kullanıcı deneyimi sunan üyelik platformudur.</p>`,
  },
  {
    question: "2. Nasıl Vestel Kimlik hesabı oluşturabilirim?",
    answer: `<p>1. Vestel Kimlik hesabı oluşturmak için ana sayfadaki "Vestel Hesabı Oluşturun" butonua veya <a href="/sign-up?id=1" style="color: #d91219"> buraya </a> 
      tıklayarak hesap oluşturma sayfasına gidiniz.</p> <br/> <p>2. Üyelik için gerekli alanları (ad, soyad, e-posta, telefon numarası) eksiksiz olarak doldurun ve bir şifre belirleyin.
      Aydınlatma Metni ve KVKK Politikası metnine onay vererek “Hesap Oluştur” butonuna tıklayın.</p> 
      <br/> <p>3. E-postanıza doğrulama linki gönderilecektir. Gelen doğrulama linkine tıklayarak hesabınızı doğruladıktan sonra e-
      posta adresiniz ve şifreniz ile sisteme giriş yapın.
      Doğrulama e-postasının “Gereksiz”, “Bilinmeyen”, “İstenmeyen” veya “Spam” klasörlerine düşebileceğini hatırlatmak
      isteriz. </p> 
      <br/> <p>4. Hesabınıza ilk giriş yaptığınızda, kayıt olurken girdiğiniz telefon numaranıza doğrulama kodu gönderilecektir.
      Telefonunuza gelen doğrulama kodunu ekrana girin.</p> <br/> <p> 5. Telefon doğrulama adımını tamamladığınızda Vestel Kimlik hesabınız başarı ile oluşturulacaktır. Ekran
      yönlendirmelerini takip ederek istediğiniz websitesi veya uygulamaya üyelik bilgileriniz ile giriş yapabilirsiniz.</p>`,
  },
  {
    question: "3. Hesabıma neden giriş yapamıyorum?",
    answer: `<p>E-posta adresinizin, telefon numaranızın ve şifrenizin doğruluğundan emin olun. Hesabınızın etkinleşmesi için
    mailinize gelen doğrulama e-postasını onaylayın ve giriş yapmayı deneyin. 
      </p> <br/> <p>Şifrenizi hatırlamıyorsanız veya doğruluğundan emin değilseniz <a href="/request-code?id=1" style="color: #d91219"> buraya </a> tıklayarak şifrenizi sıfırlayabilirsiniz. Hala
      sorun devam ederse,<span style="font-weight: bold"> bize ulaşın. </span></p>`,
  },
  {
    question: "4. Hesabım için hangi e-posta adresini kullandığımı bilmiyorum.",
    answer: `<p>E-posta adresinizi hatırlamıyorsanız, ana sayfada “SMS ile Giriş Yap” butonuna tıklayarak
    kayıtlı telefon numaranızla giriş yapabilirsiniz. Giriş yaptıktan sonra hesabınıza kayıtlı e-posta
    adresinizi görebilirsiniz.</p>`,
  },
  {
    question: "5. Doğrulama e-postası neden gelmiyor?",
    answer: `<p>E-posta adresinizi doğru yazdığınızdan emin olun. Doğrulama e-postası “Gereksiz”,
      “Bilinmeyen”, “İstenmeyen” veya “Spam” klasörlerine düşmüş olabilir, bu klasörleri kontrol
      edin. Eğer hala sorun devam ederse,<span style="font-weight: bold"> bize ulaşın. </span></p>`,
  },
  {
    question: "6. Telefonuma neden doğrulama kodu gelmiyor?",
    answer: `<p>Telefonunuza doğrulama kodu gelmiyor ise, telefon numaranızın doğru olduğundan emin
      olun. Ayrıca telefonunuzun sinyali olup olmadığını kontrol edin. Eğer hala sorun devam
      ederse,<span style="font-weight: bold"> bize ulaşın. </span></p>`,
  },
  {
    question: "7. SMS ile neden giriş yapamıyorum?",
    answer: `<p>SMS ile giriş yapabilmek için telefon numaranızın hesabınıza ekli ve doğrulanmış olması
      gereklidir. E-posta ve şifrenizle giriş yapıp telefon numaranızı doğrulayabilirsiniz.</p>`,
  },
  {
    question: "8. Şifremi unuttum, ne yapmalıyım?",
    answer: `<p>Şifrenizi unuttuysanız, ana sayfadaki "Şifremi Unuttum" butonuna veya <a href="/request-code?id=1" style="color: #d91219"> buraya </a> 
    tıklayarak, e-posta adresiniz veya telefon numaranızla şifre sıfırlama işlemini gerçekleştirebilirsiniz.</p>`,
  },
  {
    question: "9. Bilgilerimi nasıl güncelleyebilirim?",
    answer: `<p>Vestel Kimlik hesabınıza giriş yaptıktan sonra değiştirmek istediğiniz alan için “düzenle”
      butonuna tıklayarak gerekli değişiklikleri yapabilirsiniz.</p>`,
  },
  {
    question:
      "10. Farklı sitelere birden fazla e-posta adresi ile kayıt olmuştum? Ne yapmalıyım?",
    answer: `<p>Vestel Kimlik size tek hesap ile birçok platforma giriş yapma olanağı tanır. Dilediğiniz bir
      hesabınız ile giriş yaptığınızda bu hesabı tüm platformlarda kullanabilirsiniz.</p>`,
  },
  {
    question: `11. Vestel, Regal, VSOutlet, Vestel Çözümü Var, Evin Aklı veya VFit+’tan en az birinde mevcut
    üyeliğim var. Bu üyeliğimle Vestel Kimlik oluşturabilir miyim, giriş yapabilir miyim?`,
    answer: `<p>Evet, mevcut üyeliğinizi kullanarak devam edebilirsiniz, yeniden Vestel Kimlik oluşturmanıza gerek yok</p>`,
  },
  {
    question: "12. Hesabımı nasıl silebilirim?",
    answer: `<p>Hesabınızı sildiğiniz durumda Vestel Kimlik’te yer alan hiçbir website ve mobil uygulamaya
    giriş yapamayacaksınız. Yine de hesabınızı 
      silmek istiyorsanız, <a href="/user-delete" style="color: #d91219"> buraya </a> tıklayarak silme talebinizi iletebilirsiniz.</p> 
      <p>Hesabınız 10 gün boyunca pasif olacaktır. 10 gün içinde tekrar giriş yaparsanız hesap silme
      isteğiniz iptal edilecek ve hesabınız yeniden aktif edilecektir.</p>`,
  },
  {
    question: "13. Kişisel verilerim korunuyor mu?",
    answer: `Evet. Kişisel verileriniz Vestel Kimlik ile güvendedir. Vestel, ilgili yasal düzenlemelere sıkı bir
      şekilde uyar ve kişisel verilerin aktarımı, işlenmesi, saklanması ve erişimi esnasında gelişmiş
      güvenlik önlemleri alarak kişisel verilerinizin gizliliğini ve güvenliğini sağlar`,
  },
];

function Support() {
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  const toggleLeave = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(null);
  };

  return (
    <>
      <div className="content-page">
        <h3 className="process-title">Bize Ulaşın</h3>
        <div className="support-options">
          <div className="option">
            <a style={{ textDecoration: "none" }} href="tel:08502224123">
              <img
                className="live-support"
                src={liveSupport}
                alt="live-support"
              />
              <div className="support-option-title">Canlı Destek Hattı</div>
            </a>
          </div>
          <div className="option">
            <a
              style={{ textDecoration: "none" }}
              href="https://wa.me/908502224123"
            >
              <img className="whatsapp" src={whatsapp} alt="whatsapp-support" />
              <div className="support-option-title">
                Whatsapp İletişim Hattı
              </div>
            </a>
          </div>
          <div className="option">
            <a
              style={{ textDecoration: "none" }}
              href="mailto:vestelkimlik@vestel.com.tr"
            >
              <img
                className="message"
                style={{
                  width: "50px",
                  height: "35px",
                  marginBottom: "7px",
                }}
                src={message}
                alt="message-support"
              />
              <div className="support-option-title">Mail İletişim Hattı</div>
            </a>
          </div>
        </div>
        <div className="accordion-wrapper">
          <div className="accordion">
            {data.map((item, index) => (
              <div key={index} className="accordion-item">
                <div className="accordion-title" onClick={() => toggle(index)}>
                  <div className="accordion-header">
                    <h2
                      className={selected === index ? "selected-accordion" : ""}
                    >
                      {item.question}
                    </h2>
                    <div>
                      {selected === index ? (
                        <>
                          <img
                            className="accordion-minus"
                            src={minus}
                            alt="minus"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            className="accordion-plus"
                            src={plus}
                            alt="plus"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      selected === index
                        ? "accordion-content show"
                        : "accordion-content"
                    }
                    onClick={() => toggleLeave(index)}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.answer }}
                      className="accordion-section"
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;
