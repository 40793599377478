export const localStorageConst = {
  UserName: "username",
  Password: "password",
  PhoneNumber: "phoneNumber",
  IsSignClicked: "isSignClicked",
  IsDeleteSignClicked: "isDeleteSignClicked",
};

export const ErrorDefinition = {
    SingInErrorCode: "UserNotConfirmedException",
    SignInError: "E-posta adresi/Telefon numarası veya şifre hatalıdır !",
    SignInErrorAlert: "Giriş Bilgileriniz Hatalıdır",
    ChangeEmailError: "An account with the email already exists.",
    ChangeEmailErrorMessage: "E-Posta adresi sistemde mevcuttur",
    ConfirmAttributeCodeError: "Invalid verification code provided, please try again.",
    ConfirmAttributeCodeErrorMessage: "Girilen doğrulama kodu yanlıştır"
};

export const BooleanDefinition = {
    True: "true",
    False: "false",
}

export const SuccessMessage = {
    Success: "success",
    UserDelete: "Hesabınız silindi.",
}