import { useEffect, useState } from "react";
import vestelKimlik from "../assets/images/vestelKimlik.png";
import redirect1 from "../assets/images/redirect1.png";
import vestelLogo1 from "../assets/images/vestelLogo1.png";
import evinAkli1 from "../assets/images/Vestel_Akilli_Yasam_ikon_final.png";
import vfit1 from "../assets/images/Vestel_Akilli_Saglik_ikon_final.png";
import regal1 from "../assets/images/regal1.png";
import vsOutlet1 from "../assets/images/vsOutlet1.png";
import vestelCozum1 from "../assets/images/vestelCozum1.png";
import RedirectImage from "../assets/images/redirectImage.png";

import useMediaQuery from "../components/useMediaQuery";

export default function VestelIdentity() {
  const isSmallScreen = useMediaQuery("(max-width:785px)");
  const [evinAkli, setEvinAkli] = useState("");
  const [vfit, setVfit] = useState("");

  useEffect(() => {
    let userDevice = navigator.userAgent || window.opera;

    if (/iPad|iPhone|iPod/.test(userDevice) && !window.MSStream) {
      setEvinAkli("https://apps.apple.com/tr/app/evin-aklı/id966916050");
      setVfit("https://apps.apple.com/tr/app/vfit/id1491459940");
    } else {
      setEvinAkli(
        "https://play.google.com/store/apps/details?id=com.smarthome.core.main&hl=tr"
      );
      setVfit(
        "https://play.google.com/store/apps/details?id=com.vestel.vfitplus"
      );
    }
  });

  return (
    <div className="redirect-page">
      <div className="content-page identity-content-page">
        <div className="identity-overflow">
          <img
            className="vestel-kimlik-img"
            src={vestelKimlik}
            alt="vestel-kimlik"
          />
          <div>
            <div className="identity-position">VESTEL KİMLİK</div>
            <div className="identity-absolute">Birçok marka tek hesap</div>
          </div>
        </div>
        <div
          style={{
            display: "block",
            position: "absolute",
            top: "110%",
          }}
        >
          <div>
            <img className="redirect-image" src={redirect1} alt="redirect1" />
          </div>
        </div>
        <div className="redirect-p-title">
          <p className="redirect-p-center">Şimdi,</p>
          <p className="redirect-p-center-bold">giriş yapmak istediğiniz</p>
          <p className="redirect-p-center-bold">Websitesi ya da Uygulamaya</p>
          <p className="redirect-p-center-bold">tıklayın.</p>
          {isSmallScreen ? (
            <>
              <div className="sign-in-div">
                <a href="https://www.vestel.com.tr/customer/login">
                  <div className="sign-in-square">
                    <img
                      className="sign-in-img"
                      src={vestelLogo1}
                      alt="vestelLogo1"
                    />
                    <p className="sign-in-redirect-website">
                      Websitesine git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
                <a href={evinAkli}>
                  <div className="sign-in-square">
                    <img
                      style={{ width: "50px" }}
                      className="sign-in-img"
                      src={evinAkli1}
                      alt="evinAkli1"
                    />
                    <p className="sign-in-img-p">Vestel Akıllı Yaşam</p>
                    <p className="sign-in-redirect-website">
                      Uygulamaya git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
                <a href={vfit}>
                  <div className="sign-in-square">
                    <img
                      style={{ width: "50px" }}
                      className="sign-in-img"
                      src={vfit1}
                      alt="vfit1"
                    />
                    <p className="sign-in-img-p">Vestel Akıllı Sağlık</p>
                    <p className="sign-in-redirect-website">
                      Uygulamaya git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
              </div>
              <div className="sign-in-div">
                <a href="https://www.regal-tr.com/customer/login">
                  <div className="sign-in-square">
                    <img className="sign-in-img" src={regal1} alt="regal1" />
                    <p className="sign-in-redirect-website">
                      Websitesine git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
                <a href="https://www.vsoutlet.com.tr/customer/login">
                  <div className="sign-in-square">
                    <img
                      className="sign-in-img"
                      src={vsOutlet1}
                      alt="vestelLogo1"
                    />
                    <p className="sign-in-redirect-website">
                      Websitesine git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
                <a href="https://destekmerkezi.vestel.com.tr/giris-yap">
                  <div className="sign-in-square">
                    <img
                      className="sign-in-img"
                      src={vestelCozum1}
                      alt="vestelCozum1"
                    />
                    <p className="sign-in-redirect-website">
                      Websitesine git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="sign-in-div">
                <a href="https://www.vestel.com.tr/customer/login">
                  <div className="sign-in-square">
                    <img
                      className="sign-in-img"
                      src={vestelLogo1}
                      alt="vestelLogo1"
                    />
                    <p className="sign-in-redirect-website">
                      Websitesine git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
                <a href={evinAkli}>
                  <div className="sign-in-square">
                    <img
                      style={{ width: "50px" }}
                      className="sign-in-img"
                      src={evinAkli1}
                      alt="evinAkli1"
                    />
                    <p className="sign-in-img-p">Vestel Akıllı Yaşam</p>
                    <p className="sign-in-redirect-website">
                      Uygulamaya git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
                <a href={vfit}>
                  <div className="sign-in-square">
                    <img
                      style={{ width: "50px" }}
                      className="sign-in-img"
                      src={vfit1}
                      alt="vfit1"
                    />
                    <p className="sign-in-img-p">Vestel Akıllı Sağlık</p>
                    <p className="sign-in-redirect-website">
                      Uygulamaya git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
                <a href="https://www.regal-tr.com/customer/login">
                  <div className="sign-in-square">
                    <img className="sign-in-img" src={regal1} alt="regal1" />
                    <p className="sign-in-redirect-website">
                      Websitesine git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
                <a href="https://www.vsoutlet.com.tr/customer/login">
                  <div className="sign-in-square">
                    <img
                      className="sign-in-img"
                      src={vsOutlet1}
                      alt="vestelLogo1"
                    />
                    <p className="sign-in-redirect-website">
                      Websitesine git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
                <a href="https://destekmerkezi.vestel.com.tr/giris-yap">
                  <div className="sign-in-square">
                    <img
                      className="sign-in-img"
                      src={vestelCozum1}
                      alt="vestelCozum1"
                    />
                    <p className="sign-in-redirect-website">
                      Websitesine git
                      <img src={RedirectImage} alt="redirectImage" />
                    </p>
                  </div>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
