/* eslint-disable no-useless-escape */
import { useState, useEffect } from "react";

import * as yup from "yup";

export const useValidEmail = (initialValue) => {
  const [email, setEmail] = useState(initialValue);
  const [emailIsValid, setEmailIsValid] = useState(true);

  useEffect(() => {
    const emailSchema = yup.object().shape({

      email: yup.string().required().matches(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/, "E-Posta formatı yanlış."),
    });

    if (email.length === 0) {
      setEmailIsValid(true);
      return;
    }

    const isValid = emailSchema.isValidSync({ email });

    setEmailIsValid(isValid);
  }, [email]);

  return { email, setEmail, emailIsValid };
};

export const useValidPassword = (initialValue) => {
  const [password, setPassword] = useState(initialValue);
  const [passwordIsValid, setPasswordIsValid] = useState(true);

  useEffect(() => {
    const passwordSchema = yup.object().shape({
      password: yup
        .string()
        .min(8, "Password must be 8 characters long")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
    });
    if (password.length === 0) {
      setPasswordIsValid(true);
      return;
    }

    const isValid = passwordSchema.isValidSync({ password });

    setPasswordIsValid(isValid);
  }, [password]);

  return { password, setPassword, passwordIsValid };
};

export const useValidUsername = (initialValue) => {
  const [username, setUsername] = useState(initialValue);
  const [usernameIsValid, setUsernameIsValid] = useState(true);

  useEffect(() => {
    const usernameSchema = yup.object().shape({
      username: yup.string().min(8).required().matches(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/, "E-Posta formatı yanlış."),
    });

    if (username.length === 0) {
      setUsernameIsValid(true);
      return;
    }

    const isValid = usernameSchema.isValidSync({ username });

    setUsernameIsValid(isValid);
  }, [username]);

  return { username, setUsername, usernameIsValid };
};
export const useValidGivenName = (initialValue) => {
  const [givenName, setGivenName] = useState(initialValue);
  const [givenNameIsValid, setGivenNameIsValid] = useState(true);

  useEffect(() => {
    const givenNameSchema = yup.object().shape({
      givenName: yup.string().min(2).required(),
    });

    if (givenName.length === 0) {
      setGivenNameIsValid(true);
      return;
    }

    const isValid = givenNameSchema.isValidSync({ givenName });

    setGivenNameIsValid(isValid);
  }, [givenName]);

  return { givenName, setGivenName, givenNameIsValid };
};

export const useValidFamilyName = (initialValue) => {
  const [familyName, setFamilyName] = useState(initialValue);
  const [familyNameIsValid, setFamilyNameIsValid] = useState(true);

  useEffect(() => {
    const familyNameSchema = yup.object().shape({
      familyName: yup.string().min(2).required(),
    });

    if (familyName.length === 0) {
      setFamilyNameIsValid(true);
      return;
    }

    const isValid = familyNameSchema.isValidSync({ familyName });

    setFamilyNameIsValid(isValid);
  }, [familyName]);

  return { familyName, setFamilyName, familyNameIsValid };
};

export const useValidPhoneNumber = (initialValue) => {
  const [countryCode, setCountryCode] = useState({
    name: "TR +90",
    dial_code: "+90",
    code: "TR",
  });

  const [phoneNumber, setPhoneNumber] = useState(initialValue);
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true);

  useEffect(() => {
    const phoneNumberSchema = yup.object().shape({
      phoneNumber: yup.string().min(10).required(),
    });

    if (phoneNumber.length === 0) {
      setPhoneNumberIsValid(true);
      return;
    }

    const isValid = phoneNumberSchema.isValidSync({ phoneNumber });

    setPhoneNumberIsValid(isValid);
  }, [phoneNumber]);

  return {
    phoneNumber,
    setPhoneNumber,
    phoneNumberIsValid,
    countryCode,
    setCountryCode,
  };
};

export const useValidCode = (initialValue) => {
  const [code, setCode] = useState(initialValue);
  const [codeIsValid, setCodeIsValid] = useState(true);

  useEffect(() => {
    const codeSchema = yup.object().shape({
      code: yup.string().min(6).required(),
    });

    if (code.length === 6) {
      setCodeIsValid(true);
      return;
    }

    const isValid = codeSchema.isValidSync({ code });

    setCodeIsValid(isValid);
  }, [code]);

  return { code, setCode, codeIsValid };
};
