import { useState } from "react";
import passCancel from "../../assets/images/passCancel.svg";
import passCheck from "../../assets/images/passCheck.svg";

const Password = ({
  label,
  required,
  passwordIsValid,
  password,
  setPassword,
  newPassword,
  visibleValidMessage = true,
}) => {
  const [control, setControl] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordUpper, setPasswordUpper] = useState(false);
  const [passwordLower, setPasswordLower] = useState(false);
  const [passwordSpecial, setPasswordSpecial] = useState(false);
  const [passBlur, setPassBlur] = useState(false);

  const handlePassword = (e) => {
    if (e.length > 7) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }
    if (e.match(/[A-Z]/)) {
      setPasswordUpper(true);
    } else {
      setPasswordUpper(false);
    }
    if (e.match(/[a-z]/)) {
      setPasswordLower(true);
    } else {
      setPasswordLower(false);
    }
    if (e.match(/[^\w]/)) {
      setPasswordSpecial(true);
    } else {
      setPasswordSpecial(false);
    }
  };
  return (
    <>
      <div className="form-group">
        <label>
          {label}
          {required && <span>*</span>}
        </label>
        <div className="field-group">
          <div
            onClick={() => {
              setControl(!control);
            }}
            className="password-control"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="15"
              viewBox="0 0 24 15"
              fill="none"
            >
              <g opacity={control ? "1" : "0.5"}>
                <path
                  d="M11.8446 0C3.87748 0 0 7.5 0 7.5C0 7.5 4.30174 15 11.8446 15C19.3874 15 23.6891 7.5 23.6891 7.5C23.6891 7.5 19.8117 0 11.8446 0ZM11.8446 13.4354C8.64294 13.4354 6.04315 10.7806 6.04315 7.50505C6.04315 4.22948 8.638 1.56965 11.8446 1.56965C15.0511 1.56965 17.646 4.22443 17.646 7.5C17.646 10.7756 15.0462 13.4303 11.8446 13.4303V13.4354Z"
                  fill={control ? "#d91219" : "#414042"}
                />
                <path
                  d="M11.8444 4.89062C10.4384 4.89062 9.29395 6.06155 9.29395 7.49998C9.29395 8.9384 10.4384 10.1093 11.8444 10.1093C13.2504 10.1093 14.3949 8.9384 14.3949 7.49998C14.3949 6.06155 13.2504 4.89062 11.8444 4.89062Z"
                  fill={control ? "#d91219" : "#414042"}
                />
              </g>
              <defs>
                <clipPath id="clip0_36_3101">
                  <rect width="23.6842" height="15" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <input
            type={control ? "text" : "password"}
            value={password}
            onChange={(evt) => {
              setPassword(evt.target.value);
              handlePassword(evt.target.value);
              setPassBlur(true);
            }}
            onFocus={() => setPassBlur(true)}
          />
        </div>
        <div>
          {newPassword ? (
            <>
              {passBlur ? (
                <div className="valid-password-message">
                  <div style={{ color: "black" }}>
                    Geçerli bir şifre oluşturabilmek için en az;
                  </div>
                  <div>
                    {passwordLength ? (
                      <div className="success-valid-message pass-message">
                        <img src={passCheck} alt="password-check" />
                        <span>8 karakter</span>
                      </div>
                    ) : (
                      <div className="error-valid-message pass-message">
                        <img src={passCancel} alt="password-cancel" />
                        <span>8 karakter</span>
                      </div>
                    )}
                  </div>
                  <div>
                    {passwordUpper ? (
                      <div className="success-valid-message pass-message">
                        <img src={passCheck} alt="password-check" />
                        <span>1 büyük karakter</span>
                      </div>
                    ) : (
                      <div className="error-valid-message pass-message">
                        <img src={passCancel} alt="password-cancel" />
                        <span>1 büyük karakter</span>
                      </div>
                    )}
                  </div>
                  <div>
                    {passwordLower ? (
                      <div className="success-valid-message pass-message">
                        <img src={passCheck} alt="password-check" />
                        <span>1 küçük karakter</span>
                      </div>
                    ) : (
                      <div className="error-valid-message pass-message">
                        <img src={passCancel} alt="password-cancel" />
                        <span>1 küçük karakter</span>
                      </div>
                    )}
                  </div>
                  <div>
                    {passwordSpecial ? (
                      <div className="success-valid-message pass-message">
                        <img src={passCheck} alt="password-check" />
                        <span>1 özel karakter</span>
                      </div>
                    ) : (
                      <div className="error-valid-message pass-message">
                        <img src={passCancel} alt="password-cancel" />
                        <span>1 özel karakter</span>
                      </div>
                    )}
                  </div>
                  <div style={{ color: "black" }}>girmeniz gerekmektedir.</div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              {!passwordIsValid && visibleValidMessage && (
                <div className="valid-message">
                  Lütfen geçerli şifrenizi giriniz.
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Password;
