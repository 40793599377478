import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import previous from "../assets/images/previous.svg";
import question from "../assets/images/question.svg";

function Header() {
  const [backSpace, setBackSpace] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const navigateControl = () => {
    if (location.pathname === "/confirm-attribute") {
      navigate("/confirm-attribute");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/confirm-attribute"
    ) {
      return setBackSpace(false);
    } else {
      return setBackSpace(true);
    }
  }, [location.pathname]);

  return (
    <div className="header">
      <div className="mobil-header-icon go-back">
        {backSpace && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1) || navigate("/")}
          >
            <img className="prev-icon" src={previous} alt="previous-icon" />
          </div>
        )}
      </div>
      <div onClick={() => navigateControl()}>
        <h1 className="title">VESTEL KİMLİK</h1>
        <p className="title-desc">Birçok Marka, Tek Hesap</p>
        <br></br>
      </div>
      <div className="mobil-header-icon">
        <img
          className="mobil-header-question-icon"
          src={question}
          alt="question-icon"
          onClick={() => navigate("/support")}
        />
      </div>
    </div>
  );
}

export default Header;
