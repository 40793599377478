import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { useSearchParams } from "react-router-dom";
import { useLoading } from "../libs/loadingContext";

export default function ConfirmUser() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setLoading } = useLoading();

  useEffect(() => {
    const username = searchParams.get("user_name");
    const confirmationCode = searchParams.get("confirmation_code");
    try {
      setLoading(true);
      authContext.verifyCode(username, confirmationCode);
      //navigate("/sign-in");
      navigate("/", {
        state: {
          type: "confirmUser",
          message: "Hesabınız onaylandı. E-posta ile giriş yapabilirsiniz",
        },
      });
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="content-page"></div>
    </>
  );
}
