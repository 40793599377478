function Communication() {
  return (
    <>
      <div className="content-page">
        <h3 className="process-title">İletişim Tercihleri</h3>
        <div className="channel">
          <div className="channel-info">
            <div>
              <div className="channel-title">SMS</div>
            </div>
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitchSMS"
              />
              <label
                className="onoffswitch-label"
                htmlFor="myonoffswitchSMS"
              ></label>
            </div>
          </div>
          <div className="channel-info">
            <div>
              <div className="channel-title">E-Posta</div>
            </div>
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitchEposta"
              />
              <label
                className="onoffswitch-label"
                htmlFor="myonoffswitchEposta"
              ></label>
            </div>
          </div>
          <div className="channel-info">
            <div>
              <div className="channel-title">Telefon</div>
            </div>
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitchPhone"
              />
              <label
                className="onoffswitch-label"
                htmlFor="myonoffswitchPhone"
              ></label>
            </div>
          </div>
          <div className="channel-info">
            <div>
              <div className="channel-title">Whatsapp</div>
            </div>
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitchWp"
              />
              <label
                className="onoffswitch-label"
                htmlFor="myonoffswitchWp"
              ></label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Communication;
