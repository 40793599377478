import { AppRoute } from "../router";
import { useEffect, useState } from "react";
import Header from "./header";
import question from "../assets/images/question.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useLoading } from "../libs/loadingContext";
import loadingImage from "../assets/images/loading/vestel-loader.gif";

Modal.setAppElement("#root");

function Layout() {
  const [flag, setFlag] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/vestel-identity" ||
      location.pathname === "/redirect"
    ) {
      setFlag(false);
    }
  }, []);

  let navigate = useNavigate();
  const { loading } = useLoading();
  return (
    <>
      {flag ? (
        <div className="container">
          <div className="main-page">
            {loading ? (
              <div className="overlay">
                <div className="loader">
                  <img src={loadingImage} alt="loading" />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="content">
              <Header />
              <AppRoute />
              <div className="support">
                <img
                  onClick={() => navigate("/support")}
                  className="question"
                  src={question}
                  alt="question"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="main-page">
            {loading ? (
              <div className="overlay">
                <div className="loader">
                  <img src={loadingImage} alt="loading" />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="content">
              <div className="mobil-header-icon">
                <img
                  style={{
                    zIndex: "1",
                    position: "absolute",
                    right: "35px",
                    top: "60px",
                  }}
                  className="mobil-header-question-icon"
                  src={question}
                  alt="question-icon"
                  onClick={() => navigate("/support")}
                />
              </div>
              <AppRoute />
              <div className="support">
                <img
                  onClick={() => navigate("/support")}
                  className="question"
                  src={question}
                  alt="question"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Layout;
