import { useEffect, useState } from "react";
import successAlertIcon from "../assets/images/successAlertIcon.svg";

function AlertSuccess({ message }) {
  const [isShow, setIsShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsShow(false);
    }, 5000);
  }, []);
  return (
    <>
      {isShow && (
        <div className="alert alert-top">
          <div className="alert-success">
            <div className="alert-icon">
              <img src={successAlertIcon} alt="success-alert-icon" />
            </div>
            <div className="alert-message">{message}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default AlertSuccess;
