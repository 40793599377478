import { useContext, useEffect, useState } from "react";
import { useValidPassword, useValidUsername } from "../hooks/useAuthHooks";
import Username from "../components/auth/username";
import Password from "../components/auth/password";
import { AuthContext, AuthStatus } from "../context/authContext";
import Modal from "react-modal";
import DeleteUserModal from "../components/deleteUserModal";
import { useLoading } from "../libs/loadingContext";
import AlertSignInError from "../components/alertSignInError";
import {
  BooleanDefinition,
  ErrorDefinition,
  localStorageConst,
} from "../helpers/const";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function UserDelete() {
  const { username, setUsername, usernameIsValid } = useValidUsername("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const { setLoading } = useLoading();
  const [errorFlag, setErrorFlag] = useState(false);
  const [error, setError] = useState("");
  const authContext = useContext(AuthContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openOtpModal() {
    setModalIsOpen(true);
  }

  function closeOtpModal() {
    setModalIsOpen(false);
    authContext.signOut();
  }

  useEffect(() => {
    const isSignClicked = localStorage.getItem(localStorageConst.IsSignClicked);
    if (isSignClicked !== null) {
      signOutClicked();
    }
  }, []);

  useEffect(() => {
    const isDeleteSignClicked = localStorage.getItem(
      localStorageConst.IsDeleteSignClicked
    );
    if (
      isDeleteSignClicked === BooleanDefinition.True &&
      authContext.authStatus === AuthStatus.SignedIn
    ) {
      localStorage.removeItem(localStorageConst.IsDeleteSignClicked);
      openOtpModal();
    }
  }, [authContext]);

  const signInClicked = async () => {
    try {
      localStorage.setItem(localStorageConst.UserName, username);
      setLoading(true);
      await authContext.signInWithEmail(username, password);
      localStorage.setItem(localStorageConst.IsDeleteSignClicked, true);
    } catch (err) {
      if (err.code === ErrorDefinition.SingInErrorCode) {
        setError(ErrorDefinition.SignInError);
        setErrorFlag(true);
      } else {
        setError(ErrorDefinition.SignInError);
        setErrorFlag(true);
      }
    } finally {
      setLoading(false);
      setTimeout(() => {
        setErrorFlag(false);
      }, 3000);
    }
  };

  async function signOutClicked() {
    setLoading(true);
    await authContext.signOut();
    localStorage.clear();
    setLoading(false);
  }

  return (
    <div className="content-page">
      <h3 className="process-title forget-password">Hesap Sil</h3>
      <div>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </div>
      <div>
        <Password
          passwordIsValid={passwordIsValid}
          setPassword={setPassword}
          label={"Şifre"}
          required={false}
          newPassword={false}
        />
        {error && <div className="invalid-email">{error}</div>}
        {errorFlag && (
          <AlertSignInError message={ErrorDefinition.SignInErrorAlert} />
        )}
      </div>
      <div className="login-button">
        <button className="btn btn-default" onClick={signInClicked}>
          Hesabımı Sil
        </button>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeOtpModal}
          style={customStyles}
          className="Modal"
          overlayClassName="Overlay"
        >
          <DeleteUserModal />
        </Modal>
      </div>
    </div>
  );
}
export default UserDelete;
