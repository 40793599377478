import mNumberOne from "../assets/images/mobileKeyboard/numberOne.svg";
import mNumberTwo from "../assets/images/mobileKeyboard/numberTwo.svg";
import mNumberThree from "../assets/images/mobileKeyboard/numberThree.svg";
import mNumberFour from "../assets/images/mobileKeyboard/numberFour.svg";
import mNumberFive from "../assets/images/mobileKeyboard/numberFive.svg";
import mNumberSix from "../assets/images/mobileKeyboard/numberSix.svg";
import mNumberSeven from "../assets/images/mobileKeyboard/numberSeven.svg";
import mNumberEight from "../assets/images/mobileKeyboard/numberEight.svg";
import mNumberNine from "../assets/images/mobileKeyboard/numberNine.svg";
import mNumberZero from "../assets/images/mobileKeyboard/numberZero.svg";
import mNumberDelete from "../assets/images/mobileKeyboard/numberDelete.svg";
import tNumberOne from "../assets/images/tabletKeyboard/numberOne.svg";
import tNumberTwo from "../assets/images/tabletKeyboard/numberTwo.svg";
import tNumberThree from "../assets/images/tabletKeyboard/numberThree.svg";
import tNumberFour from "../assets/images/tabletKeyboard/numberFour.svg";
import tNumberFive from "../assets/images/tabletKeyboard/numberFive.svg";
import tNumberSix from "../assets/images/tabletKeyboard/numberSix.svg";
import tNumberSeven from "../assets/images/tabletKeyboard/numberSeven.svg";
import tNumberEight from "../assets/images/tabletKeyboard/numberEight.svg";
import tNumberNine from "../assets/images/tabletKeyboard/numberNine.svg";
import tNumberZero from "../assets/images/tabletKeyboard/numberZero.svg";
import tNumberDelete from "../assets/images/tabletKeyboard/numberDelete.svg";

function Keyboard({ code, setCode }) {
  const handleKeyboard = (attr) => {
    if (code !== undefined) {
      setCode((prev) => prev + attr);
    } else {
      setCode(attr);
    }
  };

  const keyboardDelete = () => {
    setCode(code.slice(0, code.length - 1));
  };
  return (
    <>
      <div className="keyboard-mobile">
        <div className="keyboard">
          <div className="row">
            <div className="col" onClick={() => handleKeyboard("1")}>
              <img src={mNumberOne} alt="m-number-one" />
            </div>
            <div className="col" onClick={() => handleKeyboard("2")}>
              <img src={mNumberTwo} alt="m-number-two" />
            </div>
            <div className="col" onClick={() => handleKeyboard("3")}>
              <img src={mNumberThree} alt="m-number-three" />
            </div>
          </div>
          <div className="row">
            <div className="col" onClick={() => handleKeyboard("4")}>
              <img src={mNumberFour} alt="m-number-four" />
            </div>
            <div className="col" onClick={() => handleKeyboard("5")}>
              <img src={mNumberFive} alt="m-number-five" />
            </div>
            <div className="col" onClick={() => handleKeyboard("6")}>
              <img src={mNumberSix} alt="m-number-six" />
            </div>
          </div>
          <div className="row">
            <div className="col" onClick={() => handleKeyboard("7")}>
              <img src={mNumberSeven} alt="m-number-seven" />
            </div>
            <div className="col" onClick={() => handleKeyboard("8")}>
              <img src={mNumberEight} alt="m-number-eight" />
            </div>
            <div className="col" onClick={() => handleKeyboard("9")}>
              <img src={mNumberNine} alt="m-number-nine" />
            </div>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="col" onClick={() => handleKeyboard("0")}>
              <img src={mNumberZero} alt="m-number-zero" />
            </div>
            <div className="col" onClick={() => keyboardDelete()}>
              <img src={mNumberDelete} alt="m-number-delete" />
            </div>
          </div>
        </div>
      </div>
      <div className="keyboard-tablet">
        <div className="keyboard">
          <div className="row">
            <div className="col" onClick={() => handleKeyboard("1")}>
              <img src={tNumberOne} alt="t-number-one" />
            </div>
            <div className="col" onClick={() => handleKeyboard("2")}>
              <img src={tNumberTwo} alt="t-number-two" />
            </div>
            <div className="col" onClick={() => handleKeyboard("3")}>
              <img src={tNumberThree} alt="t-number-three" />
            </div>
          </div>
          <div className="row">
            <div className="col" onClick={() => handleKeyboard("4")}>
              <img src={tNumberFour} alt="t-number-four" />
            </div>
            <div className="col" onClick={() => handleKeyboard("5")}>
              <img src={tNumberFive} alt="t-number-five" />
            </div>
            <div className="col" onClick={() => handleKeyboard("6")}>
              <img src={tNumberSix} alt="t-number-six" />
            </div>
          </div>
          <div className="row">
            <div className="col" onClick={() => handleKeyboard("7")}>
              <img src={tNumberSeven} alt="t-number-seven" />
            </div>
            <div className="col" onClick={() => handleKeyboard("8")}>
              <img src={tNumberEight} alt="t-number-eight" />
            </div>
            <div className="col" onClick={() => handleKeyboard("9")}>
              <img src={tNumberNine} alt="t-number-nine" />
            </div>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="col" onClick={() => handleKeyboard("0")}>
              <img src={tNumberZero} alt="t-number-zero" />
            </div>
            <div className="col" onClick={() => keyboardDelete()}>
              <img src={tNumberDelete} alt="t-number-delete" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Keyboard;
