import { useEffect, useState } from 'react';

export default function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    const handleChange = () => {
      setMatches(mediaQuery.matches);
    };

    // Initial check
    handleChange();

    // Add listener
    mediaQuery.addEventListener('change',handleChange);

    // Clean-up
    return () => {
      mediaQuery.removeEventListener('change',handleChange);
    };
  }, [query]);

  return matches;
}