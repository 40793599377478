import { BrowserRouter } from "react-router-dom";
import "./assets/styles/style.css";
import Layout from "./components/layout";
import { SWRConfig } from "swr";
import { LoadingProvider } from "./libs/loadingContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  return (
    // <GoogleReCaptchaProvider
    // reCaptchaKey="6Lceg1kmAAAAAA0DgT_u1Hh5QTYQuNHuJ9YFo1Wg"
    //language="[optional_language]"
    //useRecaptchaNet="[optional_boolean_value]"
    //useEnterprise="[optional_boolean_value]"
    // scriptProps={{
    //   async: false, // optional, default to false,
    //   defer: false, // optional, default to false
    //   appendTo: 'head', // optional, default to "head", can be "head" or "body",
    //   nonce: undefined // optional, default undefined
    // }}
    //  container={{ // optional to render inside custom element
    //   element: "[required_id_or_htmlelement]",
    //   parameters: {
    //     badge: '[inline|bottomright|bottomleft]', // optional, default undefined
    //     theme: 'dark', // optional, default undefined
    //   }
    // }}
    // >
    <>
      {/* <GoogleReCaptchaProvider
        useRecaptchaNet
        scriptProps={{ async: true, defer: true, appendTo: "body" }}
        reCaptchaKey="6LeqAkMoAAAAABQrDtYOKJKux4arDpv7-yYvA93g"
      > */}
      <BrowserRouter>
        <SWRConfig
          value={{
            // revalidateIfStale: true,
            revalidateOnMount: true,
            revalidateOnFocus: false,
          }}
        >
          <LoadingProvider>
            <ToastContainer
              hideProgressBar
              position="top-center"
              closeOnClick
              pauseOnHover={false}
            />
            <Layout />
          </LoadingProvider>
        </SWRConfig>
      </BrowserRouter>
      {/* </GoogleReCaptchaProvider> */}
    </>
    //</GoogleReCaptchaProvider>
  );
}

export default App;
