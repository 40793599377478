import { useState, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import successAlertIcon from "../assets/images/successAlertIcon.svg";
import useSWR from "swr";
import { v4 as uuid } from "uuid";
import {
  useValidEmail,
  useValidPassword,
  useValidGivenName,
  useValidFamilyName,
  useValidPhoneNumber,
} from "../hooks/useAuthHooks";
import Email from "../components/auth/email";
import Password from "../components/auth/password";
import GivenName from "../components/auth/givenName";
import FamilyName from "../components/auth/familyName";
import PhoneNumber from "../components/auth/phoneNumber";
import { AuthContext } from "../context/authContext";
import confirmMail from "../assets/images/confirmMail.svg";
import Modal from "react-modal";
import { encode } from "base-64";
import { useLoading } from "../libs/loadingContext";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
  },
};

const SignUp = () => {
  const { email, setEmail, emailIsValid } = useValidEmail("");
  const [flag, setFlag] = useState(false);
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const {
    phoneNumber,
    setPhoneNumber,
    countryCode,
    setCountryCode,
    phoneNumberIsValid,
  } = useValidPhoneNumber("");
  const { givenName, setGivenName, givenNameIsValid } = useValidGivenName("");
  const { familyName, setFamilyName, familyNameIsValid } =
    useValidFamilyName("");
  const [error, setError] = useState("");
  const [pageFlag, setPageFlag] = useState(false);
  const [created, setCreated] = useState(false);
  const [useOfPermission, setUseOfPermission] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { setLoading } = useLoading();
  const [searchParams] = useSearchParams();

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !phoneNumberIsValid ||
    phoneNumber.length === 0 ||
    !givenNameIsValid ||
    givenName.length === 0 ||
    !familyNameIsValid ||
    familyName.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    useOfPermission === false;

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const id = searchParams.get("id");
    if (id === null) {
      window.location.href = "/vestel-identity?page=signUp";
    } else {
      setPageFlag(true);
    }
  });

  const { data, isLoading } = useSWR(
    `${process.env.REACT_APP_BASE_URL}/api/Consent/Get/?bundleId=${process.env.REACT_APP_BUNDLE_ID}`,
    (url) =>
      fetch(url, {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            encode(
              process.env.REACT_APP_CMS_USERNAME +
                ":" +
                process.env.REACT_APP_CMS_PASSWORD
            ),
        },
      }).then((res) => res.json())
  );

  //Aydınlatma Metni
  const consentText = data?.items[0].text;
  let consentTypeId = [];
  if (data && data.items) {
    data?.items.map((item) => consentTypeId.push(item.consentType));
  }

  const signUpClicked = async () => {
    try {
      setLoading(true);
      setMinutes(1);
      setSeconds(59);
      const content_type = consentTypeId[0].toString();
      const version = data.version;
      const demoUsername = uuid();
      await authContext.signUpWithEmail(
        demoUsername,
        email,
        givenName,
        familyName,
        countryCode.dial_code + phoneNumber,
        password,
        content_type,
        version
      );
      setCreated(true);
      setFlag(true);
    } catch (err) {
      if (
        err.message ===
        "PreSignUp failed with error username or password is wrong."
      ) {
        setCreated(true);
        return;
      }
      if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setFlag(false);
    }, 3000);
  }, [flag]);

  function createConsentText() {
    return { __html: consentText };
  }

  const changeEmail = () => {
    setCreated(false);
  };

  const openConsentModal = () => {
    setModalIsOpen(true);
  };

  const closeConsentModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (created) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, minutes, created]);

  const signUp = pageFlag ? (
    <>
      <div className="content-page">
        <h3 className="process-title forget-password">Hesap Oluştur</h3>
        <div>
          <GivenName
            givenNameIsValid={givenNameIsValid}
            givenName={givenName}
            setGivenName={setGivenName}
            label={"Ad"}
            required={true}
          />
        </div>
        <div>
          <FamilyName
            familyNameIsValid={familyNameIsValid}
            setFamilyName={setFamilyName}
            familyName={familyName}
            label={"Soyad"}
            required={true}
          />
        </div>
        <div>
          <Email
            emailIsValid={emailIsValid}
            setEmail={setEmail}
            email={email}
            label={"E-posta"}
            required={true}
          />
          {error && <div className="invalid-email"></div>}
        </div>
        <div className="phoneNumberDropdown">
          <PhoneNumber
            phoneNumberIsValid={phoneNumberIsValid}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            label={"Telefon"}
            required={true}
          />
        </div>
        <div>
          <Password
            passwordIsValid={passwordIsValid}
            setPassword={setPassword}
            password={password}
            label={"Şifre"}
            required={true}
            newPassword={true}
          />
        </div>
        <div className="form-consent">
          <div
            className="form-check"
            onClick={() => setUseOfPermission(!useOfPermission)}
          >
            {
              <>
                <svg
                  className="main-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10.625"
                    fill="white"
                    strokeWidth={2}
                    stroke={useOfPermission ? "red" : "grey"}
                  />
                </svg>
                <svg
                  className="child-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="7"
                    fill={useOfPermission ? "red" : "white"}
                  />
                </svg>
              </>
            }
          </div>
          <div className="consent-desc">
            <span className="consent-info" onClick={() => openConsentModal()}>
              Aydınlatma Metni ve KVKK Politikası
            </span>
            ’nı okudum, onaylıyorum.
          </div>
        </div>
        <div className="signup-button">
          {isValid ? (
            <button className="btn btn-default btn-default-disabled">
              Hesap Oluştur
            </button>
          ) : (
            <button className="btn btn-default" onClick={signUpClicked}>
              Hesap Oluştur
            </button>
          )}
        </div>
        <div className="account-signin">
          Zaten bir hesabınız var mı?{" "}
          <span onClick={() => navigate("/")}>Giriş Yap</span>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeConsentModal}
          style={customStyles}
          className="Modal modal-consent"
          overlayClassName="Overlay"
        >
          {isLoading ? (
            <>
              <div>Loading...</div>
            </>
          ) : (
            <>
              <div dangerouslySetInnerHTML={createConsentText()} />
            </>
          )}
        </Modal>
      </div>
    </>
  ) : null;

  const alertSuccess = (
    <>
      {flag && (
        <div className="alert alert-top">
          <div className="alert-success">
            <div className="alert-icon">
              <img src={successAlertIcon} alt="success-alert-icon" />
            </div>
            <div className="alert-message">İşlem Başarılı.</div>
          </div>
        </div>
      )}
    </>
  );

  const accountCreated = (
    <>
      <div className="content-page">
        {alertSuccess}
        <h3 className="process-title">E-Posta Adresini Doğrula</h3>
        <div className="confirm-mail">
          <img src={confirmMail} alt="confirm-mail" />
        </div>
        <div className="confirm-info">
          E-posta doğrulamak için {email} adresine bir link gönderdik.
        </div>
        <div className="report">Not: Doğrulama e-postasını almadıysanız;</div>
        <div className="list-report">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="5"
            viewBox="0 0 6 5"
            fill="none"
          >
            <circle cx="3.1665" cy="2.5" r="2.5" fill="#D0D0D0" />
          </svg>
          <p>
            E-posta adresinizin yukarıda doğru şekilde girildiğini teyit edin.
          </p>
        </div>
        <div className="list-report">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="5"
            viewBox="0 0 6 5"
            fill="none"
          >
            <circle cx="3.1665" cy="2.5" r="2.5" fill="#D0D0D0" />
          </svg>
          <p>Spam veya junk ileti klasörünü kontrol edin.</p>
        </div>
        <div className="confirm-code confirm-code-signup">
          <div>
            Mail size ulaşmadı mı?{" "}
            {seconds > 0 || minutes > 0 ? (
              <>
                <span className="counter">
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds} sn içinde tekrar
                  gönderebilirsiniz.
                </span>
              </>
            ) : (
              <>
                <span onClick={() => signUpClicked()}>Tekrar gönder</span>
              </>
            )}
          </div>
          <div>
            Farklı bir e-posta ile{" "}
            <span onClick={() => changeEmail()}>doğrulama yap</span>
          </div>
        </div>
      </div>
    </>
  );
  return <>{!created ? signUp : accountCreated}</>;
};

export default SignUp;
