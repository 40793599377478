import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CookieStorage,
} from "amazon-cognito-identity-js";
// import * as AWS from "aws-sdk";

const userPoolId = process.env.REACT_APP_USERPOOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
  Storage: new CookieStorage({
    domain: process.env.REACT_APP_COGNITO_COOKIE_STORAGE,
    secure: true,
  }),
};

// AWS.config.region = "eu-west-1";
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: "eu-west-1:4caf4045-6fcf-422b-b210-faeb21aa6eaf",
// });

const userPool = new CognitoUserPool(poolData);

let currentUser = userPool.getCurrentUser();

export function getCurrentUser() {
  return currentUser;
}

function getCognitoUser(username) {
  const userData = {
    Username: username,
    Pool: userPool,
    Storage: new CookieStorage({
      domain: process.env.REACT_APP_COGNITO_COOKIE_STORAGE,
      secure: true,
    }),
  };
  const cognitoUser = new CognitoUser(userData);

  return cognitoUser;
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise(function (resolve, reject) {
    if (!currentUser) return reject("invalid current user");

    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err);
      } else {
        resolve(session);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function authenticateUser(access_token, id_token, refresh_token) {
  const AccessToken = new CognitoAccessToken({
    AccessToken: access_token,
  });

  const IdToken = new CognitoIdToken({
    IdToken: id_token,
  });

  const RefreshToken = new CognitoRefreshToken({
    RefreshToken: refresh_token,
  });

  const cognitoSession = new CognitoUserSession({
    IdToken: IdToken,
    AccessToken: AccessToken,
    RefreshToken: RefreshToken,
  });

  const cognitoUser = new getCognitoUser(AccessToken.payload.username);

  cognitoUser.setSignInUserSession(cognitoSession);

  getSession();
}

export async function signUpUserWithEmail(
  username,
  email,
  given_name,
  family_name,
  phone_number,
  password,
  content_type,
  version
) {
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: given_name,
      }),
      new CognitoUserAttribute({
        Name: "family_name",
        Value: family_name,
      }),
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: phone_number,
      }),
      new CognitoUserAttribute({
        Name: "custom:content_type",
        Value: content_type,
      }),
      new CognitoUserAttribute({
        Name: "custom:version",
        Value: version,
      }),
    ];
    userPool.signUp(username, password, attributeList, [], function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function verifyCode(username, code) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);
    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function getAttributeVerificationCode(attributeName) {
  return new Promise(function (resolve, reject) {
    if (!currentUser) return reject("invalid current user");
    const cognitoUser = currentUser;

    cognitoUser.getAttributeVerificationCode(attributeName, {
      onSuccess(response) {
        resolve(response);
      },
      onFailure(err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function verifyAttribute(confirmationCode, attributeName) {
  return new Promise(function (resolve, reject) {
    if (!currentUser) return reject("invalid current user");
    const cognitoUser = currentUser;

    cognitoUser.verifyAttribute(attributeName, confirmationCode, {
      onSuccess(response) {
        resolve(response);
      },
      onFailure(err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signInWithEmail(username, password) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    currentUser = getCognitoUser(username);
    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

// export async function signInWithGoogle(googleUser) {
//   return new Promise(function () {
//     const id_token = googleUser.getAuthResponse().id_token;
//     const credentials = new AWS.CognitoIdentityCredentials({
//       IdentityPoolId: "eu-west-1:4caf4045-6fcf-422b-b210-faeb21aa6eaf",
//       RoleArn: "arn:aws:iam::152844607631:role/cognitoiam_role",
//       ProviderId: "google",
//       RoleSessionName: googleUser.wt.cu,
//       Logins: {
//         "accounts.google.com": id_token,
//       },
//     });
//     AWS.config.credentials = credentials;
//     AWS.config.credentials.get(function () {
//       window.location.href = "http://localhost:3000/";
//     });
//   }).catch((err) => {
//     throw err;
//   });
// }

export async function refreshSession(refresh_token) {
  return new Promise(function (resolve, reject) {
    const username = localStorage.getItem("username");
    const cognitoUser = getCognitoUser(username);
    cognitoUser.refreshSession(refresh_token, (err, session) => {
      if (err) {
        reject(err);
      } else {
        getSession((err, session) => {
          if (err) {
            console.log(err);
          } else {
            console.log(session);
          }
        });
        resolve("basarili");
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export function signOut(cb) {
  if (currentUser) {
    currentUser.signOut(cb);
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    if (!currentUser) return reject("invalid current user");
    currentUser.getUserAttributes(function (err, attributes) {
      if (err) {
        reject(err);
      } else {
        resolve({ attributes, username: currentUser?.getUsername() });
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setAttribute(attribute) {
  return new Promise(function (resolve, reject) {
    const attributeList = [];
    const res = new CognitoUserAttribute(attribute);
    attributeList.push(res);
    if (!currentUser) return reject("invalid current user");

    currentUser.updateAttributes(attributeList, (err, res) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setProfileAttribute(given_name, family_name) {
  return new Promise(function (resolve, reject) {
    const givenName = new CognitoUserAttribute({
      Name: "given_name",
      Value: given_name,
    });
    const familyName = new CognitoUserAttribute({
      Name: "family_name",
      Value: family_name,
    });

    if (!currentUser) return reject("invalid current user");

    currentUser.updateAttributes([givenName, familyName], (err, res) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function sendCode(username) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function forgotPassword(username, code, password) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve("password updated");
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export async function changePassword(oldPassword, newPassword) {
  return new Promise(function (resolve, reject) {
    if (!currentUser) return reject("invalid current user");
    currentUser.changePassword(oldPassword, newPassword, function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
}

export async function initiateAuth(username) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    currentUser = getCognitoUser(username);
    currentUser.setAuthenticationFlowType("CUSTOM_AUTH");
    currentUser.initiateAuth(authenticationDetails, {
      onSuccess(result) {
        resolve(result);
      },
      onFailure(err) {
        reject(err);
      },
      customChallenge(challengeParameters) {
        resolve();
      },
    });
  });
}

export async function answerChallenge(code) {
  return new Promise(function (resolve, reject) {
    currentUser.sendCustomChallengeAnswer(`${code}`, {
      onSuccess(result) {
        resolve(result);
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
}
