import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { useLoading } from "../libs/loadingContext";
//import { toast } from "react-toastify";
import axios from "axios";
import { encode } from "base-64";
import PopupConfirm from "../assets/images/popupConfirm.svg";
import { SuccessMessage } from "../helpers/const";

function OtpLogin() {
  const { setLoading } = useLoading();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const cancelDeleteUser = async () => {
    try {
      setLoading(true);
      authContext.signOut();
      //toast.success("İşlem Başarılı. ");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    setLoading(true);
    try {
      const data = {
        SubId: authContext.attrInfo[0].Value,
        Username: authContext.sessionInfo.username,
        AccessToken: authContext.sessionInfo.accessToken,
      };
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/user/delete-user`, data, {
          headers: {
            Authorization:
            "Basic " +
            encode(
              process.env.REACT_APP_CMS_USERNAME +
                ":" +
                process.env.REACT_APP_CMS_PASSWORD
            ),
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        })
        .then((response) => {
          authContext.signOut();
          localStorage.clear();
          navigate("/", {
            state: {
              id: 3,
              type: SuccessMessage.Success,
              message: SuccessMessage.UserDelete,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="popup-otp">
        <img
          style={{ marginLeft: "90px" }}
          src={PopupConfirm}
          alt="confirm-alert-icon"
        />
        <h3 className="popup-otp-title">EMİN MİSİNİZ ?</h3>
        <div className="popup-otp-desc">
          Hesabınızı silmek istediğinize emin misiniz?
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <button
            style={{ backgroundColor: "#293033", marginRight: "15px" }}
            className="btn btn-default"
            onClick={() => deleteUser()}
          >
            Evet
          </button>
          <button
            className="btn btn-default"
            onClick={() => cancelDeleteUser()}
          >
            Hayır
          </button>
        </div>
      </div>
    </>
  );
}

export default OtpLogin;
