import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidEmail, useValidPhoneNumber } from "../hooks/useAuthHooks";
import { AuthContext } from "../context/authContext";
import cs from "classnames";
import { useLoading } from "../libs/loadingContext";
import PhoneNumber from "../components/auth/phoneNumber";
import Email from "../components/auth/email";
import AlertSignInError from "../components/alertSignInError";

export default function RequestCode() {
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [isPhoneActive, setIsPhoneActive] = useState(false);
  const { email, setEmail, emailIsValid } = useValidEmail("");
  const [pageFlag, setPageFlag] = useState(false);
  const {
    phoneNumber,
    setPhoneNumber,
    countryCode,
    setCountryCode,
    phoneNumberIsValid,
  } = useValidPhoneNumber("");
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { setLoading } = useLoading();
  const [searchParams] = useSearchParams();

  const isValidEmail = !emailIsValid || email.length === 0;

  const isValidPhoneNumber = !phoneNumberIsValid || phoneNumber.length === 0;

  useEffect(() => {
    const id = searchParams.get("id");
    if (id === null) {
      window.location.href = "/vestel-identity?page=forgetPassword";
    } else {
      setPageFlag(true);
    }
  });

  useEffect(() => {
    if (phoneNumber) {
      setUsername(countryCode.dial_code + phoneNumber);
    }
  }, [phoneNumber, countryCode.dial_code, setUsername]);

  useEffect(() => {
    setUsername(email);
  }, [email, setEmail]);

  useEffect(() => {
    setUsername("");
    setEmail("");
    setPhoneNumber("");
  }, [isPhoneActive, setEmail, setPhoneNumber]);

  const sendCodeClicked = async () => {
    try {
      setLoading(true);
      localStorage.setItem("username", username);
      await authContext.sendCode(username);
      navigate("/forgot-password");
    } catch (err) {
      if (
        err.message === "Attempt limit exceeded, please try after some time."
      ) {
        setError("Deneme sınırı aşıldı, lütfen bir süre sonra tekrar deneyin.");
      } else {
        setError("Bir sorun oluştu.");
      }
    } finally {
      setLoading(false);
    }
  };

  return pageFlag ? (
    <>
      <div className="content-page">
        {error && <AlertSignInError message={error} />}
        <h3 className="process-title forget-password">Şifremi Unuttum</h3>
        <div className="form-group">
          <label>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsPhoneActive(false);
              }}
              className={cs("label-phone-number", {
                ["label-active"]: !isPhoneActive,
              })}
            >
              E-Posta
            </a>{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsPhoneActive(true);
              }}
              className={cs("label-phone-number", {
                ["label-active"]: isPhoneActive,
              })}
            >
              / Telefon Numarası
            </a>
          </label>
          {!isPhoneActive ? (
            <Email
              emailIsValid={emailIsValid}
              setEmail={setEmail}
              email={email}
            />
          ) : (
            <div className="phoneNumberDropdown">
              <PhoneNumber
                phoneNumberIsValid={phoneNumberIsValid}
                setPhoneNumber={setPhoneNumber}
                phoneNumber={phoneNumber}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
              />
            </div>
          )}
        </div>
        <div className="forgot-password-info">
          Şifrenizi unuttuysanız endişelenmenize gerek yok! <br /> Hesabınıza
          ait telefon/mail adresinizi girerek kolaylıkla şifrenizi
          sıfırlayabilirsiniz.
        </div>
        <div className="forgot-password-request-btn">
          {isValidEmail && isValidPhoneNumber ? (
            <button className="btn btn-default btn-default-disabled">
              Kod Gönder
            </button>
          ) : (
            <button
              className="btn btn-default"
              onClick={() => sendCodeClicked()}
            >
              Kod Gönder
            </button>
          )}
        </div>
      </div>
    </>
  ) : null;
}
