import verifiedSuccess from "../assets/images/verifiedSuccess.svg";

function VerifiedSuccess() {
  return (
    <>
      <div className="content-page">
        <div className="success-verified">
          <div className="verified-message">
            <img
              className="verified-success-img"
              src={verifiedSuccess}
              alt="verified-success"
            />
            <div>
              Vestel ailesine hoşgeldiniz.
              <br />
              Üyeliğiniz başarıyla oluşturulmuştur.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifiedSuccess;
