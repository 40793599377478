import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useValidCode, useValidEmail } from "../hooks/useAuthHooks";
import successAlertIcon from "../assets/images/successAlertIcon.svg";
import Code from "../components/auth/code";
import Email from "../components/auth/email";
import { AuthContext } from "../context/authContext";
import AlertError from "../components/alertError";
import { useLoading } from "../libs/loadingContext";
import { ErrorDefinition } from "../helpers/const";

export default function ChangeEmail() {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const { email, setEmail, emailIsValid } = useValidEmail("");
  const { code, setCode } = useValidCode("");
  const [reset, setReset] = useState(false);
  const { setLoading } = useLoading();
  const [flag, setFlag] = useState(false);

  const location = useLocation();
  const authContext = useContext(AuthContext);

  const isValid = !emailIsValid || email.length === 0;

  const changeEmail = async () => {
    try {
      setReset(true);
      setCode("");
      setMinutes(1);
      setSeconds(59);
      const attr = { Name: "email", Value: email };
      setLoading(true);
      await authContext.setAttribute(attr);
      setFlag(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClickConfirmEmailCode = async () => {
    try {
      setLoading(true);
      await authContext.verifyAttribute(code, "email");
      await authContext.refreshSession();
      localStorage.setItem("profileUpdateSuccessMessage", "İşlem başarılı.");
      window.location.href = "/";
    } catch (err) {
      if (err.message === ErrorDefinition.ChangeEmailError) {
        setErrorMessage(ErrorDefinition.ChangeEmailErrorMessage);
        setError(err.message);
      } else {
        setError(err.message);
      }
    } finally {
      setCode("");
      setLoading(false);
    }
  };

  const verifyDifferentEmail = () => {
    setCode("");
    setReset(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 3000);
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      setFlag(false);
    }, 3000);
  }, [flag]);

  useEffect(() => {
    if (reset) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, minutes, reset]);

  const alertSuccess = (
    <>
      {flag && (
        <div className="alert alert-top">
          <div className="alert-success">
            <div className="alert-icon">
              <img src={successAlertIcon} alt="success-alert-icon" />
            </div>
            <div className="alert-message">İşlem Başarılı.</div>
          </div>
        </div>
      )}
    </>
  );

  const confirmEmail = (
    <>
      <div className="content-page">
        {alertSuccess}
        <h3 className="process-title">E-Posta Adresini Doğrula</h3>
        <div className="process-desc">
          E-posta adresinizi doğrulamak için {email} adresine bir kod gönderdik.{" "}
        </div>
        <Code code={code} setCode={setCode} />
        <div className="continue-btn">
          <button
            className="btn btn-default"
            onClick={() => handleClickConfirmEmailCode()}
          >
            Devam Et
          </button>
        </div>
        <div
          style={{ position: "inherit", marginTop: "40px" }}
          className="confirm-code"
        >
          <div>
            Kod size ulaşmadı mı?{" "}
            {seconds > 0 || minutes > 0 ? (
              <>
                <span className="counter">
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds} sn içinde tekrar
                  gönderebilirsiniz.
                </span>
              </>
            ) : (
              <>
                <span onClick={() => changeEmail()}>Tekrar gönder</span>
              </>
            )}
          </div>
          <div>
            Farklı bir e-posta ile{" "}
            <span onClick={() => verifyDifferentEmail()}>doğrulama yap</span>
          </div>
        </div>

        {error?.length > 0 && (
          <div className="profile-change-alert">
            <AlertError  message={errorMessage}/>
          </div>
        )}
      </div>
    </>
  );

  const updateEmail = (
    <>
      <div className="content-page">
        <h3 className="process-title">E-Posta Adresinizi Değiştirin</h3>
        <div className="process-desc">
          <div className="current-info">
            <div className="current-info-title">Mevcut e-posta adresi:</div>
            <div className="current-info-state">{location.state?.email}</div>
          </div>
        </div>
        <Email
          emailIsValid={emailIsValid}
          setEmail={setEmail}
          label={"Yeni e-posta adresi"}
          required={false}
        />
        <div className="process-info">
          Hesabınızla ilişkilendirmek istediğiniz e-posta adresinizi yukarıya
          girin. Bu adrese bir doğrulama kodu göndereceğiz.
        </div>
        <div className="email-change">
          {isValid ? (
            <button className="btn btn-default btn-default-disabled">
              E-Posta Adresini Değiştir
            </button>
          ) : (
            <button className="btn btn-default" onClick={() => changeEmail()}>
              E-Posta Adresini Değiştir
            </button>
          )}
        </div>
      </div>
    </>
  );

  return <>{!reset ? updateEmail : confirmEmail}</>;
}
