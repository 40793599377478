import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import AlertSuccess from "../components/alertSuccess";
import { useLoading } from "../libs/loadingContext";
import {localStorageConst} from "../helpers/const";
import useMediaQuery from "../components/useMediaQuery";
import vestelLogo1 from "../assets/images/vestelLogo1.png";
import evinAkli1 from "../assets/images/Vestel_Akilli_Yasam_ikon_final.png";
import vfit1 from "../assets/images/Vestel_Akilli_Saglik_ikon_final.png";
import regal1 from "../assets/images/regal1.png";
import vsOutlet1 from "../assets/images/vsOutlet1.png";
import vestelCozum1 from "../assets/images/vestelCozum1.png";
import RedirectImage from "../assets/images/redirectImage.png";

export default function Home() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const isSmallScreen = useMediaQuery("(max-width:785px)");
  const { setLoading } = useLoading();
  const [evinAkli, setEvinAkli] = useState("");
  const [vfit, setVfit] = useState("");

  useEffect(() => {
    let userDevice = navigator.userAgent || window.opera;

    if (/iPad|iPhone|iPod/.test(userDevice) && !window.MSStream) {
      setEvinAkli("https://apps.apple.com/tr/app/evin-aklı/id966916050");
      setVfit("https://apps.apple.com/tr/app/vfit/id1491459940");
    } else {
      setEvinAkli(
        "https://play.google.com/store/apps/details?id=com.smarthome.core.main&hl=tr"
      );
      setVfit(
        "https://play.google.com/store/apps/details?id=com.vestel.vfitplus"
      );
    }
  });

  let successMessage = localStorage.getItem("profileUpdateSuccessMessage");

  let givenName = authContext.attrInfo
    .filter((item) => item.Name === "given_name")
    .map((val) => val.Value);
  let familyName = authContext.attrInfo
    .filter((item) => item.Name === "family_name")
    .map((val) => val.Value);
  let email = authContext.attrInfo
    .filter((item) => item.Name === "email")
    .map((val) => val.Value);
  let phoneNumber = authContext.attrInfo
    .filter((item) => item.Name === "phone_number")
    .map((val) => val.Value);

  function signOutClicked() {
    setLoading(true);
    authContext.signOut();
    localStorage.clear();
    setLoading(false);
    navigate("/");
    localStorage.removeItem("profileUpdateSuccessMessage");
  }

  async function changePasswordClicked() {
    navigate("/change-password");
    localStorage.removeItem("profileUpdateSuccessMessage");
  }

  function changeEmailClicked() {
    navigate("/change-email", {
      state: {
        email: email[0],
      },
    });
    localStorage.removeItem("profileUpdateSuccessMessage");
  }
  function changePhoneClicked() {
    navigate("/change-phone", {
      state: {
        phoneNumber: phoneNumber[0],
      },
    });
    localStorage.removeItem("profileUpdateSuccessMessage");
  }
  function changeProfileClicked() {
    navigate("/change-profile");
    localStorage.removeItem("profileUpdateSuccessMessage");
  }

  useEffect(() => {
    localStorage.setItem(localStorageConst.IsSignClicked, true);
    const phoneNumber = authContext.attrInfo.find((attr) => {
      return attr.Name === "phone_number";
    });
    localStorage.setItem("phoneNumber", phoneNumber?.Value);
    const phoneVerified = authContext.attrInfo.find((attr) => {
      return attr.Name === "phone_number_verified";
    });
    if (phoneVerified === undefined || phoneVerified?.Value === "false") {
      navigate("/confirm-attribute");
    }
  });

  // const userDelete = async () => {
  //   console.log(authContext.attrInfo[0].Value);
  //   try {
  //     setLoading(true);
  //     const data = {
  //       SubId: authContext.attrInfo[0].Value,
  //       Username: authContext.sessionInfo.username,
  //     };
  //     axios
  //       .post(`${process.env.REACT_APP_BASE_URL}/api/user/post/`, data, {
  //         headers: {
  //           "content-type": "application/json",
  //           "Access-Control-Allow-Origin": "*",
  //           "Access-Control-Allow-Headers":
  //             "Origin, X-Requested-With, Content-Type, Accept",
  //         },
  //       })
  //       .then((response) => {
  //         console.log(response);
  //         authContext.signOut();
  //         navigate("/");
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setLoading(false);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <div className="content-page">
        <h3
          className={
            successMessage?.length > 0
              ? "process-title success-message-box"
              : "process-title"
          }
        >
          Oturum Açma ve Güvenlik
        </h3>
        <div className="profile">
          <div className="profile-info">
            <div>
              <div className="info-title">Ad Soyad:</div>
              <div className="info">{`${givenName[0]} ${familyName[0]}`}</div>
            </div>
            <div>
              <button
                className="btn btn-update"
                onClick={() => changeProfileClicked()}
              >
                Düzenle
              </button>
            </div>
          </div>
          <div className="profile-info">
            <div>
              <div className="info-title">E-Posta:</div>
              <div className="info">{email[0]}</div>
            </div>
            <div>
              <button
                className="btn btn-update"
                onClick={() => changeEmailClicked()}
              >
                Düzenle
              </button>
            </div>
          </div>
          <div className="profile-info">
            <div>
              <div className="info-title">Cep Telefonu:</div>
              <div className="info">{phoneNumber[0]}</div>
            </div>
            <div>
              <button
                className="btn btn-update"
                onClick={() => changePhoneClicked()}
              >
                Düzenle
              </button>
            </div>
          </div>
          <div className="profile-info">
            <div>
              <div className="info-title">Şifre:</div>
              <div className="info">**********</div>
            </div>
            <div>
              <button
                className="btn btn-update"
                onClick={() => changePasswordClicked()}
              >
                Düzenle
              </button>
            </div>
          </div>
          {/* <div className="profile-info">
            <div>
              <div className="info-title">İletişim Tercihleri:</div>
              <div className="info">Lorem, ipsum.</div>
            </div>
            <div>
              <button
                className="btn btn-update"
                onClick={() => navigate("/communication")}
              >
                Düzenle
              </button>
            </div>
          </div> */}
        </div>
        {isSmallScreen ? (
          <>
            <div className="sign-in-div">
              <a href="https://www.vestel.com.tr/customer/login">
                <div className="sign-in-square">
                  <img
                    className="sign-in-img"
                    src={vestelLogo1}
                    alt="vestelLogo1"
                  />
                  <p className="sign-in-redirect-website">
                    Websitesine git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
              <a href={evinAkli}>
                <div className="sign-in-square">
                  <img
                    style={{ width: "50px" }}
                    className="sign-in-img"
                    src={evinAkli1}
                    alt="evinAkli1"
                  />
                  <p className="sign-in-img-p">Vestel Akıllı Yaşam</p>
                  <p className="sign-in-redirect-website">
                    Uygulamaya git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
              <a href={vfit}>
                <div className="sign-in-square">
                  <img
                    style={{ width: "50px" }}
                    className="sign-in-img"
                    src={vfit1}
                    alt="vfit1"
                  />
                  <p className="sign-in-img-p">Vestel Akıllı Sağlık</p>
                  <p className="sign-in-redirect-website">
                    Uygulamaya git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
            </div>
            <div className="sign-in-div">
              <a href="https://www.regal-tr.com/customer/login">
                <div className="sign-in-square">
                  <img className="sign-in-img" src={regal1} alt="regal1" />
                  <p className="sign-in-redirect-website">
                    Websitesine git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
              <a href="https://www.vsoutlet.com.tr/customer/login">
                <div className="sign-in-square">
                  <img
                    className="sign-in-img"
                    src={vsOutlet1}
                    alt="vestelLogo1"
                  />
                  <p className="sign-in-redirect-website">
                    Websitesine git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
              <a href="https://destekmerkezi.vestel.com.tr/giris-yap">
                <div className="sign-in-square">
                  <img
                    className="sign-in-img"
                    src={vestelCozum1}
                    alt="vestelCozum1"
                  />
                  <p className="sign-in-redirect-website">
                    Websitesine git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="sign-in-div">
              <a href="https://www.vestel.com.tr/customer/login">
                <div className="sign-in-square">
                  <img
                    className="sign-in-img"
                    src={vestelLogo1}
                    alt="vestelLogo1"
                  />
                  <p className="sign-in-redirect-website">
                    Websitesine git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
              <a href={evinAkli}>
                <div className="sign-in-square">
                  <img
                    style={{ width: "50px" }}
                    className="sign-in-img"
                    src={evinAkli1}
                    alt="evinAkli1"
                  />
                  <p className="sign-in-img-p">Vestel Akıllı Yaşam</p>
                  <p className="sign-in-redirect-website">
                    Uygulamaya git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
              <a href={vfit}>
                <div className="sign-in-square">
                  <img
                    style={{ width: "50px" }}
                    className="sign-in-img"
                    src={vfit1}
                    alt="vfit1"
                  />
                  <p className="sign-in-img-p">Vestel Akıllı Sağlık</p>
                  <p className="sign-in-redirect-website">
                    Uygulamaya git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
              <a href="https://www.regal-tr.com/customer/login">
                <div className="sign-in-square">
                  <img className="sign-in-img" src={regal1} alt="regal1" />
                  <p className="sign-in-redirect-website">
                    Websitesine git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
              <a href="https://www.vsoutlet.com.tr/customer/login">
                <div className="sign-in-square">
                  <img
                    className="sign-in-img"
                    src={vsOutlet1}
                    alt="vestelLogo1"
                  />
                  <p className="sign-in-redirect-website">
                    Websitesine git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
              <a href="https://destekmerkezi.vestel.com.tr/giris-yap">
                <div className="sign-in-square">
                  <img
                    className="sign-in-img"
                    src={vestelCozum1}
                    alt="vestelCozum1"
                  />
                  <p className="sign-in-redirect-website">
                    Websitesine git
                    <img src={RedirectImage} alt="redirectImage" />
                  </p>
                </div>
              </a>
            </div>
          </>
        )}
        <div className="confirm-code logout-box">
          <div>
            <span style={{ display: "inline" }} onClick={signOutClicked}>
              Çıkış Yap
            </span>
          </div>
          {/* <div className="account-delete" onClick={userDelete}>
            Hesap Silme
          </div> */}
        </div>
        {successMessage?.length > 0 && (
          <>
            <AlertSuccess message={successMessage} />
          </>
        )}
      </div>
    </>
  );
}
