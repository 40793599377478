import { useEffect, useState } from "react";
import errorAlertIcon from "../assets/images/errorAlertIcon.svg";

function AlertSignInError({ message }) {
    const [isShow, setIsShow] = useState(true);
    useEffect(() => {
      setTimeout(() => {
        setIsShow(false);
      }, 5000);
    }, []);
    return (
      <>
        {isShow && (
          <div style={{marginLeft: "5%"}}className="alert alert-top">
            <div className="alert-error" style={{width: "auto"}}>
              <div className="alert-header">
                <div className="alert-icon">
                  <img src={errorAlertIcon} alt="success-alert-icon" />
                </div>
                <div className="alert-message">{message}</div>
              </div>
              <div className="alert-body"></div>
            </div>
          </div>
        )}
      </>
    );
  }

export default AlertSignInError;
